
import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[alphaNumericOnly]'
})
export class AlphaNumericDirective {

    constructor(public el: ElementRef) {
        const regExp = new RegExp(/[a-zA-Z0-9]/);

        this.el.nativeElement.onkeypress = (evt) => {
            if (!evt.ctrlKey && (evt.key.length === 1) && (evt.key.match(regExp) == null)) {
                evt.preventDefault();
            }
        };

    }
}
