import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, CommonService } from '../../../shared/utils/imports';

@Component({
  selector: 'app-action-confirm',
  templateUrl: './action-confirm.component.html'
})
export class ActionConfirmComponent implements OnInit {
  @Input() actionInput;
  @Output() actionOutput = new EventEmitter();

  public reasonsList = [];
  public selectedReasonKey;
  public selectedValue = 'Select';
  public userType;
  public loading = false;
  public otherReason = '';
  public action;
  public disableTextbox = true;
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService
  ) { }

  ngOnInit() {
    this.userType = this.common.getUserType();
    this.action = this.actionInput.isReschedule;
    this.getReasonsList();
  }

  /* Function for closing the popup */
  public closePopup() {
    this.actionInput.action = 'close';
    this.actionOutput.next(this.actionInput);
  }

  /* Function for  send button click in the popup */
  public sendClick() {
    this.actionInput.action = 'send';
    this.actionInput.OutputData = {
      ReasonKey: this.selectedReasonKey,
      OtherReason: this.otherReason,
      actionType: this.action
    };
    this.actionOutput.next(this.actionInput);
  }

  /* Function for dropdown toggle */
  public toggleDropdown() {
    this.actionInput.toggleDropdown = !this.actionInput.toggleDropdown;
  }

  /* Function for clear season */
  public clearReason() {
    this.selectedReasonKey = null;
    this.selectedValue = 'Select';
    this.disableTextbox = true;
  }

  /* Function for textbox change event */
  public textBoxChange() {

  }

  /* Function for dropdown select */
  public dropdownSelect(item) {
    this.selectedReasonKey = item.ReasonKey;
    this.selectedValue = item.ReasonsForCancelOrRescheduleName;
    this.actionInput.toggleDropdown = false;
    if (this.selectedValue.toLowerCase() === 'others') {
      this.disableTextbox = false;
    } else {
      this.disableTextbox = true;
    }
  }
  /* Function for hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.actionInput.toggleDropdown = false;
  }

  /* Function for radio button change event */
  public radioButtonChange() {
    this.selectedReasonKey = null;
    this.selectedValue = 'Select';
    this.getReasonsList();
  }

  /* Function for get reasons for reschedule or cancel in appointment */
  public getReasonsList() {
    this.loading = true;
    let requestBody = {};
    if (this.actionInput.page === 'ext-cancel-reschedule') {
      requestBody = {
        IsInternal: this.userType === 'Internal' ? true : false,
        RequestTypeID: this.actionInput.selectedItem.RequestTypeID || 0,
        IsCancel: this.action === 'Cancel' ? true : false,
        IsReschedule: this.action === 'Cancel' ? false : true,
        IsActionItem: false
      };
      this.getCancelRescheduleReasons(requestBody);
    } else if (this.actionInput.page === 'int-reschedule') {
      requestBody = {
        IsInternal: this.userType === 'Internal' ? true : false,
        RequestTypeID: this.actionInput.selectedItem.RequestTypeID || 0,
        IsCancel: false,
        IsReschedule: true,
        IsActionItem: false
      };
      this.getCancelRescheduleReasons(requestBody);
    } else if (this.actionInput.page === 'int-reject-pending') {
      if (this.actionInput.selectedItem.IsMedicalRecord === true) {
        this.getMRRejectReasons();
      } else {
      requestBody = {
        IsInternal: this.userType === 'Internal' ? true : false,
        RequestTypeID: this.actionInput.selectedItem.RequestTypeID || 0,
        IsCancel: false,
        IsReschedule: false,
        IsActionItem: true
      };
      this.getCancelRescheduleReasons(requestBody);
    }
    } else if (this.actionInput.page === 'mr-reject') {
      this.getMRRejectReasons();
    } else if (this.actionInput.page === 'pat-app-history') {
      requestBody = {
        IsInternal: this.userType === 'Internal' ? true : false,
        RequestTypeID: this.actionInput.selectedItem.RequestTypeID || 0,
        IsCancel: true,
        IsReschedule: false,
        IsActionItem: false
      };
      this.getCancelRescheduleReasons(requestBody);
    }
    this.reasonsList = [];
  }

  /* Function for cancel reschedule reasons */
  public getCancelRescheduleReasons(requestBody) {
    const url = ApiUrlConstants.URL.reasonsListURL;
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.length) {
        this.loading = false;
        this.reasonsList = response.Data;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  /* Function for get MR reject reasons */
  public getMRRejectReasons() {
    const url = ApiUrlConstants.URL.mrRejectReasonsURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.loading = false;
        this.reasonsList = response.Data;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  /* Function for get API reasons */
  public getAPIforReasons() {
    const url = ApiUrlConstants.URL.reasonsListURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.loading = false;
        this.reasonsList = response.Data;
      }
    }, (error) => {
      this.loading = false;
    });
  }
}
