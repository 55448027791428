import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public readonly router: Router,
    public readonly common: CommonService,
  ) { }

  /* to check whether the user can navigate to any link */
  public canActivate(): boolean {
    const userToken = this.common.getUserToken();
    const IsVerifiedUser = sessionStorage.getItem('IsVerifiedUser');
    if (userToken && IsVerifiedUser) {
      return true;
    } else {
      this.router.navigate(['./login']);
      return false;
    }
  }
}
