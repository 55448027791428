import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-clinic-auto-complete',
  templateUrl: './clinic-auto-complete.component.html'
})
export class ClinicAutoCompleteComponent implements OnInit {
  @ViewChild('auto') auto;
  @Output() autoClinicOutput = new EventEmitter();
  @Input() autoClinicInput;
  public searchParam;
  public keyword = 'Name';
  public clinicList = [];
  public clinicListComplete = [];

  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    this.getClinicList();
  }

  /* get the list of locations */
  private getClinicList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.clinicsListURL;
    const requestBody = {
      PageCount: null,
      PageNumber: null,
      Name: ''
    };
    this.clinicList = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.length) {
        this.clinicList = response.Data;
        this.clinicListComplete = response.Data;
        if (this.autoClinicInput.selectedID) {
          this.clinicList.forEach(element => {
            if (element.Id === this.autoClinicInput.selectedID) {
              this.autoClinicInput.selectedValue = element.Name;
            }
          });
        }

        /* auto populate the value for searchParam if in case of edit screen */
        this.searchParam = this.autoClinicInput.selectedValue;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }
  /* triggered when input is changed */
  public clinicInput() {
    if (this.searchParam) {
      this.searchClinicFromList(this.searchParam);
    } else {
      this.clinicList = this.clinicListComplete;
    }
  }
  /* fn to search the param in list */
  public searchClinicFromList(searchStr) {
    this.clinicList = [];
    this.clinicListComplete.forEach(element => {
      if (element.Name) {
        if (element.Name.toLowerCase().startsWith(searchStr.toLowerCase())) {
          this.clinicList.push(element);
        }
      }
    });
  }
  /* fn triggered when an item is selected from the list */
  public selectEvent(event) {
    this.autoClinicOutput.next(event);
  }
  /* triggered when the panel is closed */
  public closePanel() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });
    this.clinicList = this.clinicListComplete;
  }
  /* clear the input field */
  public clearClinic() {
    this.searchParam = '';
    this.autoClinicOutput.next(null);
    this.clinicList = this.clinicListComplete;
    this.auto.close();
  }
  /* open the dropdown */
  public openPanel() {
    this.auto.open();
  }
  public opened() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
}
