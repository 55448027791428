import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'fileSize'
})

export class FileSizePipe implements PipeTransform {
    transform(size) {
        if (size) {
            if ((size / 1024) < 1024) {
                return Math.round(size / 1024) + ' KB';
            } else {
                if ((size / (1024 * 1024)) < 1024) {
                    return Math.round(size / (1024 * 1024)) + ' MB';
                } else {
                    return Math.round(size / (1024 * 1024 * 1024)) + ' GB';
                }
            }
        }
    }
}