import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bulk-confirm',
  templateUrl: './bulk-confirm.component.html'
})
export class BulkConfirmComponent implements OnInit {
  @Output() bulkConfirmOutput = new EventEmitter();
  @Input() bulkConfirmInput;
  constructor() { }

  ngOnInit() {
  }

  /* Function for close confirm dialog */
  closeConfirm(action) {
    this.bulkConfirmInput.action = action;
    this.bulkConfirmOutput.next(this.bulkConfirmInput);
  }
}
