import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-auto-complete-city',
  templateUrl: './auto-complete-city.component.html'
})
export class AutoCompleteCityComponent implements OnInit {
  @Output() cityOutput = new EventEmitter();
  @Input() cityInput;
  public searchParam;
  public cityArray = [];
  public keyword = 'Name';
  public displayField = true;
  public cityList = [];
  public cityComplete = [];

  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    if (this.cityInput) {
      this.searchParam = this.cityInput.Name;
    } else {
      this.searchParam = '';
    }
    this.getCityList();
  }

  /* Function for get the list of locations */
  private getCityList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.citiesListURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.cityList = response.Data;
        this.cityComplete = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.cityList = [];
    });
  }

  /* Function for triggered when input is changed */
  public cityListInput() {
    this.cityList = [];
    this.cityComplete.forEach(element => {
      if (element.Name.toLowerCase().startsWith(this.searchParam.toLowerCase())) {
        this.cityList.push(element);
      }
    });
  }

  /** Function for trigger when an item is selected from the list
   * @param event output event
   */
  public selectEvent(event) {
    this.cityInput.Name = event.Name;
    this.cityInput.ID = event.ID;
    this.cityOutput.next(this.cityInput);
  }

  /* Function for trigger when the panel is closed */
  public closePanel() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });
    this.cityList = this.cityComplete;
  }

  /* Function for clear the input field */
  public clearCity() {
    this.searchParam = '';
    this.cityOutput.next(null);
    this.cityList = this.cityComplete;
  }
  public opened() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
}
