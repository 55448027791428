import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';

@Component({
  selector: 'app-auto-complete-country',
  templateUrl: './auto-complete-country.component.html'
})
export class AutoCompleteCountryComponent implements OnInit {

  @Output() countryOutput = new EventEmitter();
  @Input() countryInput;
  public searchParam;
  public countryArray = [];
  public keyword = 'Name';
  public displayField = true;
  public countryList = [];
  public countryComplete = [];
  public selecteventData =
  {
    ID: 1,
    Name: 'US'
  };
  public initValue;
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    if (this.countryInput && this.countryInput.Name && this.countryInput.ID) {
      this.searchParam = this.countryInput.Name;
      this.selecteventData.Name = this.countryInput.Name;
      this.selecteventData.ID = this.countryInput.ID;
      this.initValue = this.countryInput.Name;
    } else {
      this.countryInput.ID = 1;
      this.countryInput.Name = 'US';
      this.selecteventData.ID = 1;
      this.selecteventData.Name = 'US';
      this.initValue = 'US';
      this.searchParam = 'US';
    }
    this.getCountryList();
    this.selectEvent(this.selecteventData);
  }

  /* Function for get the list of locations */
  private getCountryList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.countriesListURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.countryList = response.Data;
        this.countryComplete = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.countryList = [];
    });
  }

  /* Function for trigger when input is changed */
  public countryListInput() {
    this.countryList = [];
    this.countryComplete.forEach(element => {
      if (element.Name.toLowerCase().startsWith(this.searchParam.toLowerCase())) {
        this.countryList.push(element);
      }
    });
  }

  /** Function for trigger when an item is selected from the list
   * @param event output event
   */
  public selectEvent(event) {
    // tslint:disable-next-line: triple-equals
    if (event == 'US') {
      this.countryInput.Name = 'US';
      this.countryInput.ID = 1;
      this.countryOutput.next(this.countryInput);
    } else {
     this.countryInput.Name = event.Name;
     this.countryInput.ID = event.ID;
     this.countryOutput.next(this.countryInput);
    }
  }

  /* Function for trigger when the panel is closed */
  public closePanel() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });
    this.countryList = this.countryComplete;
  }

  /* Function for clear the input field */
  public clearCountry() {
    this.searchParam = '';
    this.countryOutput.next(null);
    this.countryList = this.countryComplete;
  }
  public opened() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
}
