import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '../constants/arrays';
@Pipe({
    name: 'formattedDate'
})

export class FormattedDatePipe implements PipeTransform {
    transform(date) {
        if (date) {
            date = new Date(date);
            const day = date.getDate();
            const monthNames = ArrayConstants.shortMonths;
            const year = date.getFullYear();
            return ('0' + day).slice(-2) + ' ' + monthNames[date.getMonth()] + ' ' + year.toString().substr(-2);
        }
    }
}
