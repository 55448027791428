import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/utils/imports';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly common: CommonService
  ) { }

  ngOnInit() {
  }

  /* Function for go back to dashboard */
  public gotoDashboard() {
    if (this.common.getUserType() === 'External') {
      this.router.navigate(['dashboard/external']);
    } else {
      this.router.navigate(['dashboard/internal']);
    }
  }
}
