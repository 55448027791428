import { Component, OnInit, Output, EventEmitter, Input, ViewChild, SimpleChanges } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';

@Component({
  selector: 'app-auto-complete-city-state',
  templateUrl: './auto-complete-city-state.component.html'
})
export class AutoCompleteCityStateComponent implements OnInit {
  @ViewChild('auto') auto;
  @Output() stateOutput = new EventEmitter();
  @Input() stateInput;
  @Input() stateInput2;
  public cityStateArray = [];
  public citySearch = '';
  keyword = 'Name';
  public displayField = true;
  public cityStateList = [];

  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if ((this.stateInput == null) && this.auto) {
      this.stateInput = this.stateInput2;
      this.auto.clear();
      this.cityStateArray = [];
    }
  }

  ngOnInit() {
    if (this.stateInput) {
      this.citySearch = this.stateInput.selectedValue;
    } else {
      this.citySearch = '';
    }
  }

  /* Function for fetch city list in auto select dropdown */
  public getCityList() {
    this.loader.startLoader();
    let url = ApiUrlConstants.URL.cityStateListURL;
    url = url + this.citySearch;
    this.cityStateList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.ResponseCode === 200 && response.Data) {
        this.cityStateArray = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.cityStateList = [];
    });
  }

  /* Function for search city/state input */
  public cityStateInput() {
    if (this.citySearch) {
      if (this.citySearch.length >= 3) {
        this.getCityList();
      } else {
        this.cityStateArray = [];
      }
    }
  }

  /** Function for select state ouput event
   * @param event output event
   */
  public selectEvent(event) {
    this.stateOutput.next(event);
  }

  /* Function for close panel */
  public closePanel() {
    this.cityStateArray = [];
  }
  /* Function for clear city */
  public clearCity() {
    this.citySearch = '';
    this.cityStateArray = [];
    this.stateOutput.next(null);
    this.auto.close();
  }
}
