import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayConstants } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-transport-filter',
  templateUrl: './transport-filter.component.html'
})
export class TransportFilterComponent implements OnInit {
  @Input() dropdownFilterTransport;
  @Output() transportFilterOutput = new EventEmitter();
  public selectedValue = 'ALL';
  public transportList = ArrayConstants.yesOrNoArray;
  constructor() { }

  ngOnInit() {
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterTransport.value = 'ALL';
    this.dropdownFilterTransport.key = null;
    this.transportFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterTransport.value = item.value;
    this.dropdownFilterTransport.key = item.key;
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterTransport.show = false;
  }

}
