import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-under-construction',
  templateUrl: './page-under-construction.component.html',
  styleUrls: ['./page-under-construction.component.scss']
})
export class PageUnderConstructionComponent implements OnInit {
  @Output() underConstructOutput = new EventEmitter();
  
  constructor(
  ) { }

  ngOnInit() {
  }

  /* Function for go back to appointments */
  public gotoAppointments() {
    this.underConstructOutput.next(false);
  }
}
