import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {
  @Input() isFromChaNote : boolean = false

  constructor() { }
  ngOnInit() {
    if(this.isFromChaNote)this.addAddition()
  }

  public loaderProgress = {
    progress: 0
  };
  private addition = 100;
  private loading = true;
  
  private addAddition() {
    setTimeout(() => {
      this.loaderProgress.progress += this.addition / 2;
      this.addition /= 2;
      if (this.loading) {
        this.addAddition();
      } else {
        this.loaderProgress.progress = 0;
      }
    }, 1000);
  }
}
