import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html'
})
export class PhoneNumberComponent implements OnInit {
  @Input() phoneNumberInput;
  @Output() phoneNumberOutput = new EventEmitter();
  public phoneNumberSection;
  public sendClicked;
  public matches;
  public phoneNumberMask: any[] = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor( public common: CommonService) { }

  ngOnInit() {
  }

  /* Function for closing the popup */
  public closePopup(item) {
    this.phoneNumberInput.action = 'close';
    document.getElementsByTagName('select')[item].value = 'false';
    this.phoneNumberOutput.next(this.phoneNumberInput);
  }

  /* Function for send button click in the popup */
  public sendClick() {
    this.sendClicked = true;
    if (this.phoneNumberSection) {
      this.phoneNumberInput.action = 'send';
      this.phoneNumberInput.phoneNumber = this.phoneNumberSection;
      this.phoneNumberOutput.next(this.phoneNumberInput);
    }
  }

  /* Function for disable button*/
  public disablesendButton() {
    this.matches = this.phoneNumberSection.match(/\d/g).length;
    if ( this.matches < 10) {
      return true;
    } else {
      return false;
    }
  }
}
