import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-external-user-role',
  templateUrl: './external-user-role.component.html'
})
export class ExternalUserRoleComponent implements OnInit {
  @Input() dropdownFilterUserRole;
  @Output() roleFilterOutput = new EventEmitter();
  public userTypeList = [];

  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getUserTypes();
  }

  ngOnInit() {
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterUserRole.RoleName = 'ALL';
    this.dropdownFilterUserRole.RoleID = '';
    this.roleFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterUserRole.RoleName = item.RoleName;
    this.dropdownFilterUserRole.RoleID = item.RoleID;
    this.roleFilterOutput.next(item);
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterUserRole.show = false;
  }
  /* get the list of locations */
  private getUserTypes() {
    this.loader.startLoader();
    let url = ApiUrlConstants.URL.userRolesURL;
    url = url.replace('USERTYPE', 'Internal');
    this.userTypeList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.userTypeList = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }
}
