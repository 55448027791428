import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';

@Component({
  selector: 'app-request-type-filter',
  templateUrl: './request-type-filter.component.html'
})
export class RequestTypeFilterComponent implements OnInit {
  @Input() dropdownFilterRequestType;
  @Output() requestTypeFilterOutput = new EventEmitter();
  public showList;
  public requestTypeList = [];
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getRequestTypeList();
  }
  ngOnInit() {
    this.showList = this.dropdownFilterRequestType.show;
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterRequestType.RequestTypeName = 'ALL';
    this.dropdownFilterRequestType.RequestTypeKey = null;
    this.requestTypeFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterRequestType.RequestTypeName = item.RequestTypeName;
    this.dropdownFilterRequestType.RequestTypeKey = item.RequestTypeKey;
    this.requestTypeFilterOutput.next(item);
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterRequestType.show = false;
  }
  /* get the list of locations */
  private getRequestTypeList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.requestTypeURL;
    this.requestTypeList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.requestTypeList = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }

}
