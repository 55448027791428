import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayConstants } from '../../../constants/arrays';
@Component({
  selector: 'app-duplicate-filter',
  templateUrl: './duplicate-filter.component.html'
})
export class DuplicateFilterComponent implements OnInit {
  @Input() dropdownFilterDuplicate;
  @Output() duplicateFilterOutput = new EventEmitter();
  public duplicateList = ArrayConstants.duplicateArray;
  constructor() { }

  ngOnInit() {
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterDuplicate.value = 'ALL';
    this.dropdownFilterDuplicate.key = '';
    this.duplicateFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterDuplicate.value = item.value;
    this.dropdownFilterDuplicate.key = item.key;
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterDuplicate.show = false;
  }
}
