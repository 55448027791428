import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '../constants/arrays';
@Pipe({
    name: 'phoneNumberPattern'
})

export class PhoneNumberPatternPipe implements PipeTransform {
    transform(phoneNumber) {
        if (phoneNumber === null || phoneNumber === '') {
            return '';
        } else {
            /* API response for phone includes '-' which is to be removed before adding pattern */
            phoneNumber = phoneNumber.replace('-', '').replace('-', '');
            return phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10);
        }

    }
}
