import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayConstants } from '../../../constants/arrays';

@Component({
  selector: 'app-usertype-filter',
  templateUrl: './usertype-filter.component.html'
})
export class UsertypeFilterComponent implements OnInit {
  @Input() dropdownFilterUsertype;
  @Output() userTypeFilterOutput = new EventEmitter();
  public userTypeList = ArrayConstants.userTypeArray;
  constructor() { }

  ngOnInit() {
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterUsertype.value = 'ALL';
    this.dropdownFilterUsertype.key = '';
    this.userTypeFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterUsertype.value = item.value;
    this.dropdownFilterUsertype.key = item.key;
    this.userTypeFilterOutput.next(item);
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterUsertype.show = false;
  }

}
