import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IMyDrpOptions, IMyDateSelected, IMyCalendarViewChanged } from 'mydaterangepicker';
import { ObjectConstants } from '../../constants/objects';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
})
export class DateRangePickerComponent implements OnInit {
  @Input() dateRangePickerInput;
  @Output() datePickerOutput = new EventEmitter();

  public myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'dd.mm.yyyy',
    inline: true,
    monthLabels: ObjectConstants.datepickerMonths
  };
  public dateValue: any = {
    beginDate: { year: null, month: null, day: null },
    endDate: { year: null, month: null, day: null }
  };
  constructor() { }

  ngOnInit() {
    this.initializeDate();
  }
  /* fn for highlighting the already selected dates in calender */
  private initializeDate() {
    this.dateValue.beginDate = this.dateRangePickerInput.beginDate;
    this.dateValue.endDate = this.dateRangePickerInput.endDate;
    this.dateValue.beginJsDate = this.dateRangePickerInput.beginJsDate;
    this.dateValue.endJsDate = this.dateRangePickerInput.endJsDate;
  }

  /*
   * fn for clearing the date fields
   * clear the ng model value and clear the two way binding value
   */
  public clearDates() {
    this.dateValue = '';
    this.dateRangePickerInput.beginJsDate = '';
    this.dateRangePickerInput.endJsDate = '';
    this.dateRangePickerInput.beginDate = { year: null, month: null, day: null };
    this.dateRangePickerInput.endDate = { year: null, month: null, day: null };
    this.datePickerOutput.next('clear');
  }
  /** fn triggered on selection of dates
   * @param event output event
   */
  public onDateSelected(event: IMyDateSelected) {
    if (event.type === 1) {
      this.dateValue = {};
      this.dateValue.beginDate = event.date;
      this.dateValue.beginJsDate = event.jsdate;
      this.dateRangePickerInput.beginDate = event.date;
      this.dateRangePickerInput.beginJsDate = event.jsdate;
      this.dateRangePickerInput.endDate = '';
      this.dateRangePickerInput.endJsDate = '';
    } else if (event.type === 2) {
      this.dateValue = {};
      this.dateValue.endDate = event.date;
      this.dateValue.endJsDate = event.date;
      this.dateRangePickerInput.endDate = event.date;
      this.dateRangePickerInput.endJsDate = event.jsdate;
      this.closeDatePicker();
    }
  }
  /* fn triggered for today button click */
  public todayClicked() {
    const today = new Date();
    this.setStartDate(today);
    this.setEndDate(today);
    this.closeDatePicker();
  }
  /* fn triggered for yesterday button click */
  public yesterdayClicked() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.setStartDate(yesterday);
    this.setEndDate(yesterday);
    this.closeDatePicker();
  }
  /* fn triggered for last week button click */
  public lastWeekClicked() {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    this.setStartDate(lastWeek);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.setEndDate(yesterday);

    this.closeDatePicker();
  }
  /* fn triggered for last month button click */
  public lastMonthClicked() {
    const nowdate = new Date();
    const monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth() - 1, 1);
    this.setStartDate(monthStartDay);
    const monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 0);
    this.setEndDate(monthEndDay);

    this.closeDatePicker();
  }
  /* set the start date values */
  private setStartDate(date) {
    this.dateValue.beginDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.dateRangePickerInput.beginDate = this.dateValue.beginDate;

    this.dateValue.beginJsDate = date;
    this.dateRangePickerInput.beginJsDate = date;
  }
  /* set the end date values */
  private setEndDate(date) {
    this.dateValue.endDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.dateRangePickerInput.endDate = this.dateValue.endDate;

    this.dateValue.endJsDate = date;
    this.dateRangePickerInput.endJsDate = date;
  }
  /** hide the datepicker on clicking outside
   * @param event output event
   */
  public datePickerClickOutside(event) {
    this.closeDatePicker();
  }

  /* close the datepicker */
  public closeDatePicker() {
    this.datePickerOutput.next('close');
  }
}
