import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chartnote-popup',
  templateUrl: './chartnote-popup.component.html'
})
export class ChartnotePopupComponent implements OnInit {
  @Output() chartnoteOutput = new EventEmitter();
  @Input() chartViewInput;
  constructor() { }

  ngOnInit() {
  }

  /* Function for close the modal */
  public closeChartnote() {
    this.chartnoteOutput.next('close');
  }
}
