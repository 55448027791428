import { environment } from 'src/environments/environment';

export class ApiUrlConstants {
  /* api base url */
  public static readonly baseUrl = environment.baseURL;

  public static readonly URL = {
    /* url for login api */
    loginURL: `${ApiUrlConstants.baseUrl}login`,

    /* url for register user api */
    registerURL: `${ApiUrlConstants.baseUrl}register`,

    /* url for user transaction after login */
    usertransactionURL: `${ApiUrlConstants.baseUrl}user/usertransaction`,

    /* url for reset password */
    resetPasswordURL: `${ApiUrlConstants.baseUrl}user/resetpassword`,

    /* url for checking password link expiry */
    passwordLinkExpiryURL: `${ApiUrlConstants.baseUrl}user/checktokenexpiry`,

    /* url for forgot password */
    forgotPasswordURL: `${ApiUrlConstants.baseUrl}user/forgetpassword`,

    /* url for locations */
    locationsURL: `${ApiUrlConstants.baseUrl}user/getlocationslist`,

    /* url for location list in external */
    externallocationsURL: `${ApiUrlConstants.baseUrl}user/getappointmentlocations`,

    /* url for listing the users */
    userListingURL: `${ApiUrlConstants.baseUrl}users/getuserslist`,

    /* url for list of titles */
    titleListURL: `${ApiUrlConstants.baseUrl}gettitle`,

    /* url for getting the user roles */
    userRolesURL: `${ApiUrlConstants.baseUrl}role/usertype?usertype=USERTYPE`,

    /* url for getting the user previlleges */
    userPrevilegesURL: `${ApiUrlConstants.baseUrl}privileges?RoleID=ROLE_ID&UserType=USER_TYPE`,

    /* url for getting the user permissions */
    userPermissionsURL: `${ApiUrlConstants.baseUrl}privilegepermissions`,

    /* url for getting the user information */
    userInfoURL: `${ApiUrlConstants.baseUrl}userinfo`,

    /* url for creating a new user */
    createUserURL: `${ApiUrlConstants.baseUrl}users/createuser`,

    /* url for updating a user with previlege */
    updateUserURL: `${ApiUrlConstants.baseUrl}updateuserwithPrivilege`,

    /* url for deleting user*/
    deleteUserURL: `${ApiUrlConstants.baseUrl}DeleteAUser?deletedUserID=DELETE_USER_ID&loggedInUserID=LOGGED_USER_ID`,

    /* url for getting the list of all users */
    getAllUsersURL: `${ApiUrlConstants.baseUrl}UserSearch?search=SEARCH_PARAM&clinicID=Clinic_ID`,

    /* url for getting the list of users in a clinic */
    clinicUsersListURL: `${ApiUrlConstants.baseUrl}clinic/users?ClinicID=CLINIC_ID&ClinicName=CLINIC_NAME`,

    /* url for getting the clinic information */
    clinicInfoURL: `${ApiUrlConstants.baseUrl}clinic?ClinicID=CLINIC_ID`,

    /* url for deleting clinic*/
    deleteClinicURL: `${ApiUrlConstants.baseUrl}clinic?ClinicID=CLINIC_ID&ClinicName=CLINIC_NAME&User=USER_ID`,

    /* url for removing user from clinic */
    removeUserFromClinicURL: `${ApiUrlConstants.baseUrl}removeuserfromclinic`,

    /* url for removing user from clinic */
    createNewClinicURL: `${ApiUrlConstants.baseUrl}clinic`,

    /* url for activate or deactivate clinic */
    activateClinicURL: `${ApiUrlConstants.baseUrl}activateclinic`,

    /* url for appointment status dropdown */
    appointmentStatusURL: `${ApiUrlConstants.baseUrl}dashboard/appointmentstatuslist`,

    /* url for appointment listing table */
    appointmentListURL: `${ApiUrlConstants.baseUrl}dashboard/appointmentslist`,

    /* url for search patient records table */
    patientRecordsURL: `${ApiUrlConstants.baseUrl}patientrecords`,

    /* url for transport dropdown change in today's dashboard */
    transportChangeURL: `${ApiUrlConstants.baseUrl}appointmenttransport`,

    /* url for request types dropdown list */
    requestTypeURL: `${ApiUrlConstants.baseUrl}requesttypeslist/`,

    /* url for fetching all clinics list */
    clinicsListURL: `${ApiUrlConstants.baseUrl}getallclinics`,

    /* url for fetching city list */
    cityStateListURL: `${ApiUrlConstants.baseUrl}cityandstate?search=`,

    /* url for fetching reasons for cancel or reschedule list */
    reasonsListURL: `${ApiUrlConstants.baseUrl}dashboard/reasonsforcancelorreschedulelist`,

    /* url for fetching pending dashboard list */
    pendingListURL: `${ApiUrlConstants.baseUrl}pendinglist`,

    /* url for cancelling or rescheduling any appointment */
    cancelRescheduleURL: `${ApiUrlConstants.baseUrl}appointments/RescheduleOrCancel`,

    /* url for approving pending appointment */
    approvePendingURL: `${ApiUrlConstants.baseUrl}appointments/Approve`,

    /* url for rejecting pending appointment */
    rejectPendingURL: `${ApiUrlConstants.baseUrl}appointments/Reject`,

    /* url for approve or reject MR appointment */
    approveRejectMRURL: `${ApiUrlConstants.baseUrl}ApproveOrRejectMR`,

    /* url for fetching MR list */
    MRListingURL: `${ApiUrlConstants.baseUrl}getmedicalrecords`,

    /* url for fetching medical records MR request */
    mrRecordTypeURL: `${ApiUrlConstants.baseUrl}getmedicalrecordtypes`,

    /* url for requesting medical records */
    requestMRURL: `${ApiUrlConstants.baseUrl}RequestMedicalRecords`,

    /* url for terms and conditions accepatance */
    termsAndConditionsAcceptURL: `${ApiUrlConstants.baseUrl}termsAndCondtnAccept`,

    /* url for sending MFA mail to external users */
    sendMFAMailForExternalUsers: `${ApiUrlConstants.baseUrl}sendMFAMailForExternalUsers`,

    /* url for status dropdown in MR table */
    mrStatusListURL: `${ApiUrlConstants.baseUrl}medicalrecordstatuses`,

    /* url for reject reasons in MR table */
    mrRejectReasonsURL: `${ApiUrlConstants.baseUrl}medicalrequestreasons`,

    /* url for all countries list */
    countriesListURL: `${ApiUrlConstants.baseUrl}getallCountry`,

    /* url for all states list */
    statesListURL: `${ApiUrlConstants.baseUrl}getallState`,

    /* url for all cities list */
    citiesListURL: `${ApiUrlConstants.baseUrl}getallCities`,

    /* url for refering provider list */
    referingProviderListURL: `${ApiUrlConstants.baseUrl}getallreferringprovider`,

    /* url for languages list */
    languagesListURL: `${ApiUrlConstants.baseUrl}getalllanguages`,

    /* url for pharmacies list */
    pharmacyListURL: `${ApiUrlConstants.baseUrl}getallPharmacies`,

    /* url for pharmacies list for pharmacy and medications tab */
    pharmaciesListURL: `${ApiUrlConstants.baseUrl}pharmacy/getallPharmacies`,

    /* url for pharmacies list for pharmacy and medications tab */
    pharmacyByPatientIdURL: `${ApiUrlConstants.baseUrl}pharmacy/getPharmacyByPatientId?PatientID=PATIENT_ID`,

    /* url for primary care provider list */
    pcpListURL: `${ApiUrlConstants.baseUrl}getallPrimaryCareProvider?clinicID=CLINIC_ID&isExternal=IS_EXTERNAL`,

    /* url for special needs list */
    specialNeedsListURL: `${ApiUrlConstants.baseUrl}getallSpecialNeeds`,

    /* url for patient details fetching */
    // tslint:disable-next-line: max-line-length
    patientDetailsURL: `${ApiUrlConstants.baseUrl}getpatientdemographics?PatientID=PATIENT_ID&appointmentID=APPOINTMENT_ID&userKey=USER_KEY`,

    // get patient details in header
    patientHeaderDetailsURL: `${ApiUrlConstants.baseUrl}getpatientnamedob?PatientID=PATIENT_ID&userKey=USER_KEY`,

    /* url for update patient information */
    updatePatientInfoURL: `${ApiUrlConstants.baseUrl}updatepatientinformation`,

    /* url for update patient address */
    updatePatientAddressURL: `${ApiUrlConstants.baseUrl}updatepatientaddress`,

    /* url for update patient demographics */
    updatePatientDemoURL: `${ApiUrlConstants.baseUrl}updatepatientdemographics`,

    /* url for appointment history list */
    appHistoryListURL: `${ApiUrlConstants.baseUrl}appointmenthistorylist?patient=PATIENT_ID`,

    /* url for reasons dropdown in patient page */
    reasonsInPateintURL: `${ApiUrlConstants.baseUrl}getallReasons`,

    /* url for companies dropdown in patient page */
    companiesListURL: `${ApiUrlConstants.baseUrl}getallCompanies`,

    /* url for adding new patient info */
    addPatientURL: `${ApiUrlConstants.baseUrl}addpatientdemographics`,

    /* url for creating new appointment */
    createAppointmentURL: `${ApiUrlConstants.baseUrl}CreateNewAppointment`,

    /* url for listing chartnotes table */
    chartNotesListURL: `${ApiUrlConstants.baseUrl}GetChartNotesList?patientID=PATIENT_ID&skip=SKIP&top=TOP&documentGrpupID=DOCUMENTGRPID `,

    /* url for downloading chartnotes */
    // tslint:disable-next-line: max-line-length
    chartNotesDownloadURL: `${ApiUrlConstants.baseUrl}FileAPI/GetFile?patientID=PATIENT_ID&dateOfRequest=DATE_REQUEST&isDownload=IS_DOWNLOAD&chartNoteId=CHART_NOTE_ID`,

    /* url for sending notification */
    sendNotificationURL: `${ApiUrlConstants.baseUrl}sendnotification`,

    /* url for listing notification */
    listNotificationURL: `${ApiUrlConstants.baseUrl}getnotifications?user=USER_KEY`,

    /* url for reading notification */
    readNotificationURL: `${ApiUrlConstants.baseUrl}readnotification?user=USER_KEY`,

    /* url for receving notification */
    receiveNotificationURL: `${ApiUrlConstants.baseUrl}getnewnotifications`,

    /* url for receving refresh token */
    receiveRefreshTokenURL: `${ApiUrlConstants.baseUrl}user/createrefreshtoken`,

    /* url for get registration request details */
    registrationRequestURL: `${ApiUrlConstants.baseUrl}pending/getRegisterDetails`,

    /* url for update registration request details */
    updateRegistrationReqURL: `${ApiUrlConstants.baseUrl}pending/updateRegisterDetails`,

    /* url for individual file upload */
    fileUploadURL: `${ApiUrlConstants.baseUrl}FileAPI/UploadFile`,

    /* url for multiple files upload */
    filesUploadURL: `${ApiUrlConstants.baseUrl}FileAPI/UploadFiles`,

    /* url for deleting uploaded file */
    deleteUploadURL: `${ApiUrlConstants.baseUrl}ChartNotes/Delete?docID=DOC_ID&userID=USER_ID`,

    /*url for first time login  */
    ShowInternalLogPopURL: `${ApiUrlConstants.baseUrl}updateUserDepartment`,

    /*url for listing dashboards in pop-up for first time login*/
    ShowListingLogPopURL: `${ApiUrlConstants.baseUrl}getallDepartments`,

    /*url for listing user-access report*/
    userAccessReportURL: `${ApiUrlConstants.baseUrl}pcpUpdateRequestReportURL`,

    // tslint:disable-next-line: max-line-length
    fileUploadNew: `${ApiUrlConstants.baseUrl}FileDetails/UploadFiles?fileName=FILE_NAME&userID=USER_ID&fileType=FILE_TYPE&fileSize=FILE_SIZE&patientID=PATIENT_ID&appointmentID=APPOINTMENT_ID&firstName=FIRST_NAME&lastName=LAST_NAME&dob=DATE_OF_BIRTH&isMessage=IS_MESSAGE`,

    /* url for  view download list */
    viewdownloadlistURL: `${ApiUrlConstants.baseUrl}viewdownloadlist`,

    /*url for exporting excel*/
    userAccessReportExportExcelURL: `${ApiUrlConstants.baseUrl}exportuseraccesstoexcel`,

    /*url for user activity*/
    userActivityURL: `${ApiUrlConstants.baseUrl}useractivitylist`,

    /*url for user activity*/
    userActExportURL: `${ApiUrlConstants.baseUrl}exportuseractivitytoexcel`,

    /*url for appointment overview list*/
    appoverviewURL: `${ApiUrlConstants.baseUrl}appointmentoverview`,

    /*url for appointment overview  summary*/
    appoverviewsummaryURL: `${ApiUrlConstants.baseUrl}summaryappointmentoverview`,

    /*url for app overview export excel */
    appOverviewExportURL: `${ApiUrlConstants.baseUrl}exportappointmentoverviewtoexcel`,

    /*url for response turn around list */
    reportsTurnAroundListURL: `${ApiUrlConstants.baseUrl}responseturnaround`,

    /*url for appointment type list */
    appTypeListURL: `${ApiUrlConstants.baseUrl}appointmenttypeslist`,

    /*url for excel export in response turn around */
    exportResponseTurnURL: `${ApiUrlConstants.baseUrl}exportresponseturnaroundtoexcel`,

    /* url for get instructions */
    instructionsURL: `${ApiUrlConstants.baseUrl}getinstructions`,

    /* url for get instruction document list */
    instructionsDocumentList: `${ApiUrlConstants.baseUrl}getinstructionfile?instructionType=`,

    /* referal history api URL */
    referalHistoryListURL: `${ApiUrlConstants.baseUrl}referralhistory/getrequests`,

    /* donwload referal history api URL */
    downloadRefHistoryURL: `${ApiUrlConstants.baseUrl}referralhistory/download?appointmentKey=APPOINTMENT_KEY&documentId=DOCUMENT_ID`,

    /* send referal history api URL */
    sendRefHistoryURL: `${ApiUrlConstants.baseUrl}referralhistory/sendtextmessage`,

    /* get referal history messages api URL */
    getMessagesRefHistoryURL: `${ApiUrlConstants.baseUrl}referralhistory/getmessages?appointmentKey=APPOINTMENT_KEY&UserKey=USER_KEY`,

    /*get directory URL */
    directoryURL: `${ApiUrlConstants.baseUrl}directorylist?isExternal=USER_TYPE`,

    /* get directory document list URL */
    directoryDocumentListURL: `${ApiUrlConstants.baseUrl}getdirectorydocuments?directoryTypeId=`,

    /*get directory document URL */
    directoryDocumentURL: `${ApiUrlConstants.baseUrl}getdirectorydocumentpath`,

    /* get upload by multi part URL */
    uploadMultiPartURL: `${ApiUrlConstants.baseUrl}FileDetails/UploadFilesByPart?`,

    /* get download pdf URL */
    downloadPdfURL: `${ApiUrlConstants.baseUrl}downloadfile?appointmentKey=APPOINTMENT_KEY&documentId=DOCUMENT_ID`,

    /* url for fetching pending dashboard list */
    externalPendingListURL: `${ApiUrlConstants.baseUrl}pendinglistforexternal `,

    /* url for fetching hedis report list*/
    externalHedisListUrl: `${ApiUrlConstants.baseUrl}hedisreportlist`,

    /*url for excel export in response turn around */
    hedisExportResponseTurnURL: `${ApiUrlConstants.baseUrl}hedisreportlisttoexcel`,

    /*url for get capitation list */
    getCapitationListUrl: `${ApiUrlConstants.baseUrl}capitation/getcapitationlist`,

    /*url for get capitation excel type */
    getCapitationCategoryListUrl: `${ApiUrlConstants.baseUrl}capitation/getcategorieslist`,

    /*url for upload capitation excel  */
    uploadCapitationExcelUrl: `${ApiUrlConstants.baseUrl}capitation/UploadCapitationExcel?`,

    /*url for excel export in today dashboard */
    todayDashboardExcelUrl: `${ApiUrlConstants.baseUrl}dashboard/appointmentslistexport`,

    /*url for upload patient info*/
    patientInfoUploadUrl: `${ApiUrlConstants.baseUrl}patient/uploadpatientdemographics?`,

    /*url for verification */
    verificationUrl: `${ApiUrlConstants.baseUrl}user/verifyuser`,

    /*url for deactivate a user */
    deactivateUrl: `${ApiUrlConstants.baseUrl}/deactivateuser`,

    /* Url for signalR connection update */
    updateconnectionurl: `${ApiUrlConstants.baseUrl}updateconnection?connectionId=CONNECTION_ID&UserKey=USER_KEY`,

    /*url for unlock a user */
    unlockUrl: `${ApiUrlConstants.baseUrl}/unlockuser?user=USER_KEY`,
    // tslint:disable-next-line: max-line-length
    // patientInfoUploadUrl:`${ApiUrlConstants.baseUrl}patient/uploadpatientdemographics?userKey=USER_KEY&fileName=FILE_NAMEd&fileType=FILE_TYPE&fileSize=FILE_SIZE`

    /*url for unlock a user */
    lockUrl: `${ApiUrlConstants.baseUrl}/lockuser?user=USER_KEY`,

    /*url for document group list */
    documentListUrl: `${ApiUrlConstants.baseUrl}GetDocumentGroupList`,

    /*url for document group list */
    userEmailListExportUrl: `${ApiUrlConstants.baseUrl}users/useremailexport`,

    /* url for sending update request for pharmacies change */
    updatePharmacyMailRequest: `${ApiUrlConstants.baseUrl}pharmacy/updatePharmacyMailRequest`,

    /* url for sending update request for PCP change */
    updatePCPMailRequest: `${ApiUrlConstants.baseUrl}patient/updatePCPMailRequest`,

    /* url for getting patient medications by patient id */
    patientMedicationsListUrl: `${ApiUrlConstants.baseUrl}getAllMedicationsByPatientId?PatientID=PATIENT_ID&skip=SKIP&top=TOP`,

    /* url for pharmacies update requests list by patient id for pharmacy and medications tab */
    PharmacyUpdateRequestsByPatientIdUrl: `${ApiUrlConstants.baseUrl}getPharmacyUpdateRequestsByPatientId?PatientID=PATIENT_ID`,

    /* url for pharmacies update requests list by patient id for pharmacy and medications tab */
    MedicationRefillRequestsByPatientIdUrl: `${ApiUrlConstants.baseUrl}getMedicationRefillRequestsByPatientId?PatientID=PATIENT_ID`,

    /* url for medications list by patient id for pharmacy and medications tab */
    medicationsByPatientIdURL: `${ApiUrlConstants.baseUrl}getMedicationsByPatientId?PatientID=PATIENT_ID`,

    /* url for sending request for medications refill */
    refillMedicationMailRequest: `${ApiUrlConstants.baseUrl}refillMedicationsMailRequest`,

    /* url for sending request for medications refill */
    pcpUpdateRequestReportURL: `${ApiUrlConstants.baseUrl}pcpUpdateRequestReportURL`,

    /*url for document group list */
    pcpUpdateRequestReportExportUrl: `${ApiUrlConstants.baseUrl}pcpUpdateRequestExportURL`,

    /* url for getting  T and C approval report */
    termsAndCondnAcceptanceReportURL: `${ApiUrlConstants.baseUrl}termsAndCondnAcceptanceReportURL`

  };
}
