import { Component, OnInit, ViewChild, HostListener, OnChanges, } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, ApiUrlConstants, ApiService, LoaderService } from '../../../shared/utils/imports';
import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnChanges {
  public logoutClicked = false;
  public showInternalTab;
  public showExternalTab;
  public logoutHeader = false;
  public userType;
  public userName = '';
  public notificationsList: any[];
  public userID = this.common.getUserID();
  public notificationData = {
    totalUnreadRecords: 0
  };
  public totalUnreadRecords = 0;
  public notificationBar = false;
  public showNotificationOnetime = false;
  public APItimeOUT;
  public toasterTimeout;
  public notificationCount;
  public previousUrl;
  public signalvaue: boolean = false;
  private _hubConnection: HubConnection;
  showimage: boolean = false;
  signaldata: any[] = [];
  constructor(
    private readonly router: Router,
    private readonly common: CommonService,
    private readonly api: ApiService,
    public readonly loader: LoaderService,
    private http: HttpClient,

  ) {
    this.checkPageType();
    this.userName = this.common.getUsername();
  }
  ngOnChanges() {
    console.log("ngonchanges")
  }
  /* Function for set the observable variable to toggle the tab for internal and external */
  public checkPageType() {
    this.userType = this.common.getUserType();
    if (this.userType === 'Internal') {
      this.common.setInternalHeader(true);
      this.common.setExternalHeader(false);
    } else if (this.userType === 'External') {
      this.common.setInternalHeader(false);
      this.common.setExternalHeader(true);
    }
  }
  // updateSignalConnection(data) {
  //   let url = ApiUrlConstants.URL.updateconnectionurl;
  //   url = url.replace('CONNECTION_ID', data.id);
  //   url = url.replace('USER_KEY', this.common.getUserID());
  //   this.api.ExecuteGet(url, (response) => {
  //     if (response.Data) {
  //     }
  //   }, (error) => {
  //   });
  // }
  ngOnInit() {
    this.notificationBar = false;
    this.common.signalrRefreshCheck.subscribe(val => {
      this.signalvaue = val;
    });
    // if (parseInt(JSON.parse(sessionStorage.getItem('IscallSignalR'))) == 0 || this.signalvaue) {
    if (this.signalvaue) {
      this._hubConnection = new HubConnectionBuilder().withUrl('https://broadcast-qa.cnergycloud.net:8090/notify', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();
      this._hubConnection.serverTimeoutInMilliseconds = 1000 * 1000;
      this._hubConnection.start()
        .then(() =>
          console.log('connection start'))
        .catch(err => {
          console.log('Error while establishng connection')
        });
      this._hubConnection.on('BroadcastNotification', (message) => {
        this.signaldata.push(message);
        if (this.userID == message.receiverId) {
          this.notificationData = message;
          this.totalUnreadRecords = message.totalUnreadRecords;
          this.notificationBar = true;
          this.showimage = true;
          this.common.signalTotalCountSource.next(message.totalUnreadRecords);
          this.common.signalShowImageSource.next(true);
          this.common.signalNotificationBarSource.next(true);
          this.closeNotificationInterval();
        }
      })
      this.http.get("https://broadcast-qa.cnergycloud.net:8090/api/Notification").subscribe(
        data => {
          console.log(data);
        }
      );
      this.common.signalrRefreshSource.next(false);
    }
    this.common.signalTotalCountCheck.subscribe(value => {
      this.totalUnreadRecords = value;
    });
    this.common.signalShowImageCheck.subscribe(value => {
      this.showimage = value;
    });
    this.common.signalNotificationBarCheck.subscribe(value => {
      this.notificationBar = value;
    });

    this.common.externalHeaderCheck.subscribe(value => {
      this.showExternalTab = value === true ? this.userType === 'External' ? true : false : false;
    });
    this.common.internalHeaderCheck.subscribe(value => {
      this.showInternalTab = value === true ? this.userType === 'Internal' ? true : false : false;
    });
    // if (sessionStorage.getItem('unreadNotification')) {
    //   this.totalUnreadRecords = parseInt(JSON.parse(sessionStorage.getItem('unreadNotification')), 0);
    // }
    this.getNewNotification();
    if (parseInt(JSON.parse(sessionStorage.getItem('IscallSignalR'))) == 0) {
      sessionStorage.setItem('IscallSignalR', '1');
    }
    // /* call the new notification api at every 30 seconds */
    // this.APItimeOUT = setInterval(() => {
    //   this.getNewNotification();
    // }, 30000);
    // this.notificationCount = setInterval(() => {
    //   if (sessionStorage.getItem('unreadNotification')) {
    //     this.totalUnreadRecords = parseInt(JSON.parse(sessionStorage.getItem('unreadNotification')), 0);
    //   }
    // }, 100);
  }
  /* tslint:disable-next-line:use-life-cycle-interface */
  ngOnDestroy() {
    /* clear the new notification api timer while leaving the page */
    clearTimeout(this.APItimeOUT);
  }

  sessionTimeout() {
    this.common.setTimeoutValue(true);
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  /* Function for logout action */
  public showLogoutPopup() {
    this.logoutClicked = true;
  }

  /* Function for goto appointmnet dashboard on logo click */
  public gotoAppointmentDash() {
    const userType = this.common.getUserType();
    switch (userType) {
      case 'Internal':
        this.router.navigate(['dashboard/internal']);
        this.common.setInternalDashboardTab(true);
        break;
      case 'External':
        this.common.setExternalDashboardTab(true);
        this.router.navigate(['dashboard/external']);
        break;
    }
  }

  /* Function for logout popup */
  public logout() {
    this.logoutHeader = !this.logoutHeader;
  }

  /* Function for close logout popup */
  public closeLogout(event) {
    this.logoutClicked = event;
    this.logoutHeader = false;
  }

  /* Function for logo click */
  public logoClicked() {
    this.gotoAppointmentDash();
  }

  /* Function for closing the dropdown on clicking outside */
  public closeDropdown(event: Event) {
    this.logoutHeader = false;
  }

  /* Function for go to search page */
  public gotoSearchPage() {
    this.router.navigate(['dashboard/external/today-dashboard/request-appointment']);
  }

  /* Function for read notification */
  public readNotificationsList() {
    this.loader.startLoader();
    sessionStorage.setItem('unreadNotification', '0');
    this.common.signalTotalCountSource.next(0);
    let url = ApiUrlConstants.URL.readNotificationURL;
    url = url.replace('USER_KEY', this.userID);
    this.api.ExecuteGet(url, (response) => {
      if (response) {
        this.router.navigate(['dashboard/common/notifications/listing']);
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }

  /* Function for get new notifications */
  public getNewNotification() {
    // this.notificationBar = false;
    const url = ApiUrlConstants.URL.receiveNotificationURL;
    const currentDate = new Date();
    const currentDateMinute = currentDate.getMinutes() - 40;
    const startDate = new Date();
    const startDateMinute = startDate.setMinutes(currentDateMinute) - 40;
    const currentTDateTime = new Date().toISOString();
    const startTDateTime = new Date(startDateMinute).toISOString();

    const requestBody = {
      User: this.userID,
      StartDate: startTDateTime,
      EndDate: currentTDateTime,
    };
    // this.notificationBar = false;
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.ResponseCode === 200) {
        this.notificationData = response.Data;
        this.totalUnreadRecords = response.Data.totalUnreadRecords;
        // this.notificationBar = false;

        if (response.Data.totalUnreadRecords > 0 && this.router.url === '/dashboard/common/notifications/listing') {
          if (response.Data.totalUnreadRecords !== JSON.parse(sessionStorage.getItem('unreadNotification'))) {
            this.common.setCallValue(true);
          }

        }

        if (sessionStorage.getItem('unreadNotification') && JSON.parse(sessionStorage.getItem('unreadNotification')) !== 0) {
          if ((response.Data.totalUnreadRecords > JSON.parse(sessionStorage.getItem('unreadNotification')))) {
            sessionStorage.setItem('unreadNotification', response.Data.totalUnreadRecords);
            // this.notificationBar = true;

            // this.closeNotificationInterval();
          }
        } else {
          if (response.Data.totalUnreadRecords > 0) {
            sessionStorage.setItem('unreadNotification', response.Data.totalUnreadRecords);
            // this.notificationBar = true;

            // this.closeNotificationInterval();
          }
        }

      }
    }, (error) => {

    });
  }

  /* Function for close notification */
  public closeNotification() {
    this.notificationBar = false;
    this.common.signalNotificationBarSource.next(false);
    clearTimeout(this.toasterTimeout);
  }

  /* Function for Auto hide notification */
  public closeNotificationInterval() {
    this.toasterTimeout = setInterval(() => {
      /* clear the timer after showing the toaster for 5 seconds */
      this.notificationBar = false;
      this.common.signalNotificationBarSource.next(false);
      clearTimeout(this.toasterTimeout);
    }, 5000);
  }
}
