export class ArrayConstants {
    /* Yes or No array */
    public static readonly yesOrNoArray = [
        {
            key: 'Yes',
            value: 'Yes'
        },
        {
            key: 'No',
            value: 'No'
        }
    ];

    /* Array for short month names */
    public static readonly shortMonths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    /* Yes or No array */
    public static readonly genderArray = [
        {
            key: 'Male',
            value: 'Male'
        },
        {
            key: 'Female',
            value: 'Female'
        },
        {
            key: 'Unknown',
            value: 'Unknown'
        },
        {
            key: 'Undifferentiated',
            value: 'Undifferentiated'
        }
    ];
    /* Yes or No array */
    public static readonly userTypeArray = [
        {
            key: 'Internal',
            value: 'Internal'
        },
        {
            key: 'External',
            value: 'External'
        }
    ];

    /* items per page array */
    public static readonly itemsPerPageArray = [
        {
            key: 10,
            value: 10
        },
        {
            key: 50,
            value: 50,
        },
        {
            key: 100,
            value: 100
        }
    ];

    /* duplicate filter array */
    public static readonly duplicateArray = [
        {
            key: 'Yes',
            value: 'Yes'
        },
        {
            key: 'No',
            value: 'No'
        }
    ];

    /* status of request in MR filter */
    public static readonly statusOfMRArray = [
        {
            StatusID: 1,
            StatusName: 'COMPLETED'
        },
        {
            StatusID: 2,
            StatusName: 'DECLINED'
        },
        {
            StatusID: 3,
            StatusName: 'PENDING'
        }
    ];

    /* file types in attachments */
    public static readonly fileTypes = [
        '.pdf',
        '.xls',
        '.doc',
        '.jpeg',
        '.png'
    ];
}
