import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayConstants } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-last-hour-filter',
  templateUrl: './last-hour-filter.component.html',
  styleUrls: ['./last-hour-filter.component.scss']
})
export class LastHourFilterComponent implements OnInit {
  @Input() dropdownFilterLastHour;
  @Output() lastHourFilterOutput = new EventEmitter();
  public selectedValue = 'ALL';
  public LastHourList = ArrayConstants.yesOrNoArray;
  constructor() { }

  ngOnInit() {
  }

  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterLastHour.value = 'ALL';
    this.dropdownFilterLastHour.key = 0;
    this.lastHourFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterLastHour.value = item.value;
    this.dropdownFilterLastHour.key = item.key;
    this.lastHourFilterOutput.next(item.value);
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterLastHour.show = false;
  }


}
