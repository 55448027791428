import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'formattedTime'
})

export class FormattedTimePipe implements PipeTransform {
    transform(date) {
        if (date) {
            date = date + 'Z';
            date = new Date(date);
            let hours = date.getHours();
            let min = date.getMinutes();

            let amPm;
            if (hours < 12) {
                amPm = 'AM';
            } else {
                hours = hours - 12;
                amPm = 'PM';
            }
            if (hours === 0) {
                hours = 12;
            }
            if (min < 10) {
                min = '0' + min;
            }


            return hours + ':' + min + ' ' + amPm;
            } else {
            return '';
        }
    }
}
