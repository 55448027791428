import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/utils/imports';
import { interval } from 'rxjs';
@Component({
  selector: 'app-header-external',
  templateUrl: './header-external.component.html'
})
export class HeaderExternalComponent implements OnInit {
  public showActiveTab = 'today';
  public showTabDropdown = '';
  public dashboardName = 'Today';
  public activeDropdownValue = '';
  public isicare = false;
  activeSubTab = '';
  isReport = false;
  constructor(
    private readonly router: Router,
    private readonly common: CommonService
  ) {
    const secondsCounter = interval(100);
    secondsCounter.subscribe(n => {
      this.checkTabSelection();
    });
  }

  ngOnInit() {
    this.isicare = JSON.parse(sessionStorage.getItem('IsIcareClinic'));
    this.common.externalDashboardTabCheck.subscribe(value => {
      if (value) {
        this.common.setExternalDashboardTab(false);
      }
    });
    this.reportsHideBtn();
  }
  public reportsHideBtn() {
    const userRole = this.common.getUserRole();
    const viewReports = this.common.getViewReportsPermission();
    if (userRole) {
      if (userRole.toLowerCase() === 'admin' && viewReports) {
        this.isReport = true;
        // return true;
      } else {
        this.isReport = false;
        // return false;
      }
    } else {
      this.isReport = false;
      // return false;
    }
  }
  /* Function for navigating to tab */
  public gotoMainTabs(tabName) {
    this.showActiveTab = tabName;
    if (this.showActiveTab !== this.showTabDropdown) {  // to solve the issue in toggling the dropdown as well as active tab
      this.showTabDropdown = '';
    }
    if (tabName === 'reports') {
      this.showTabDropdown === '' ? this.showTabDropdown = tabName : this.showTabDropdown = '';
    }
    if (tabName === 'app-drop') {
      this.showTabDropdown === '' ? this.showTabDropdown = tabName : this.showTabDropdown = '';
    }
    switch (tabName) {
      case 'app-drop':
        this.showTabDropdown = 'app-drop';
        // this.router.navigate(['dashboard/external/today-dashboard']);
        break;
      case 'reports':
        this.showTabDropdown = 'reports';
        break;
      case 'locations':
        document.location.href = 'http://www.araneye.com/locations';
        break;
      case 'doctors':
        document.location.href = 'http://www.araneye.com/our-doctors';
        break;
      case 'directory':
        this.showTabDropdown = '';
        this.router.navigate(['dashboard/common/directory']);
        break;
      case 'FAQ':
        document.location.href = 'http://www.araneye.com';
        break;
      case 'contact-us':
        document.location.href = 'http://www.araneye.com/contact';
        break;
      case 'hedis':
        this.showTabDropdown = '';
        this.router.navigate(['dashboard/external/hedis']);
        break;
      case 'instructions':
        this.showTabDropdown = '';
        this.router.navigate(['dashboard/external/instructions']);
        break;
      case 'capitation-external':
        this.showTabDropdown = '';
        this.router.navigate(['dashboard/external/capitation-external']);
        break;
    }
  }

  /* Function for navigating into user management tabs */
  public gotoDropdownInnerTabs(tabName) {
    this.activeDropdownValue = tabName;
    this.showTabDropdown = '';
    switch (tabName) {
      case 'user-access':
        this.router.navigate(['dashboard/external/reports/user-access']);
        break;
      case 'user-activity':
        this.router.navigate(['dashboard/external/reports/user-activity']);
        break;
      case 'res-turnaround-time' :
        this.router.navigate(['dashboard/external/reports/res-turnaround-time']);
        break;
      case 'pending-dash':
        this.dashboardName = 'Pending Dashboard';
        this.router.navigate(['dashboard/external/external-pending-dashboard']);
        break;
      case 'today':
        this.dashboardName = 'Today';
        this.router.navigate(['dashboard/external/today-dashboard']);
        break;
      default:
        break;
    }
  }


  /* Function for closing the dropdown on clicking outside */
  public closeDropdown(event: Event) {
    this.showTabDropdown = '';

  }
  /* Function for checking active tab */
  public checkTabSelection() {
    if (this.showActiveTab !== this.showTabDropdown) {
      /* to make active which tab is active on refresh check the url*/
      switch (this.router.url.split('/')[3]) {
        case 'today-dashboard':
          this.showActiveTab = 'app-drop';
          if (this.router.url.split('/')[3] === 'today-dashboard') {
            this.activeDropdownValue = 'today';
            this.dashboardName = 'Today';
          }
          break;
        case 'external-pending-dashboard':
          this.showActiveTab = 'app-drop';
          if (this.router.url.split('/')[3] === 'external-pending-dashboard') {
            this.activeDropdownValue = 'pending-dash';
            this.dashboardName = 'Pending Dashboard';
          }
          break;
        case 'reports':
          this.showActiveTab = 'reports';
          if (this.router.url.split('/')[4] === 'user-access') {
            this.activeDropdownValue = 'user-access';
          }
          if (this.router.url.split('/')[4] === 'user-activity') {
            this.activeDropdownValue = 'user-activity';
          }
          if (this.router.url.split('/')[4] === 'res-turnaround-time') {
            this.activeDropdownValue = 'res-turnaround-time';
          }
          break;
        case 'hedis':
          this.showTabDropdown = '';
          this.showActiveTab = 'hedis';
          this.router.navigate(['dashboard/external/hedis']);
          break;
        case 'instructions':
          this.showTabDropdown = '';
          this.showActiveTab = 'instructions';
          this.router.navigate(['dashboard/external/instructions']);
          break;
        case 'directory':
          this.showTabDropdown = '';
          this.showActiveTab = 'directory';
          this.router.navigate(['dashboard/common/directory']);
          break;
        case 'capitation-external':
          this.showTabDropdown = '';
          this.showActiveTab = 'capitation-external';
          this.router.navigate(['dashboard/external/capitation-external']);
          break;
        default:
          break;
      }
    }
  }
}
