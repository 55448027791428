import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster-popup.component.html'
})
export class ErrorPopupComponent implements OnInit {
  @Output() toasterOutput = new EventEmitter();
  @Input() toasterInput;
  constructor() { }

  ngOnInit() {
  }

  /* Function for closing the toaster */
  public closeToaster() {
    this.toasterInput.showToaster = false;
    this.toasterOutput.next(this.toasterInput);
  }
}
