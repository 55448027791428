import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClickOutsideModule } from 'ng4-click-outside';
import { TextMaskModule } from 'angular2-text-mask';
import {ProgressBarModule} from "angular-progress-bar"
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

import { ApiService } from './services/api-service.service';

import { ErrorPopupComponent } from './components/toaster-popup/toaster-popup.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { NumbersDirective } from './directives/number-only.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HeaderInternalComponent } from './components/header-internal/header-internal.component';
import { HeaderExternalComponent } from './components/header-external/header-external.component';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';

import { DateRangeFilterComponent } from './components/filter-elements/date-range-filter/date-range-filter.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { MyDateRangePickerModule } from 'mydaterangepicker';

import { LocationFilterComponent } from './components/filter-elements/location-filter/location-filter.component';
import { StatusFilterComponent } from './components/filter-elements/status-filter/status-filter.component';
import { FullStatusFilterComponent } from './components/filter-elements/full-status-filter/full-status-filter.component';
import { TransportFilterComponent } from './components/filter-elements/transport-filter/transport-filter.component';
import { PhoneNumberComponent } from './components/today-dashboard-popup/phone-number/phone-number.component';
import { ActionConfirmComponent } from './components/approve-reject-action/action-confirm.component';
import { ApiUrlConstants } from './constants/api-url';
import { FormattedDatePipe } from './pipes/formatted-date.pipe';
import { FormattedTimePipe } from './pipes/formatted-time.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ObjectConstants } from './constants/objects';
import { GenderFilterComponent } from './components/filter-elements/gender-filter/gender-filter.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DOBFilterComponent } from './components/filter-elements/dob-filter/dob-filter.component';
import { AppointmentTableComponent } from './components/tables/appointment-table/appointment-table.component';
import { RequestTypeFilterComponent } from './components/filter-elements/request-type-filter/request-type-filter.component';
import { DuplicateFilterComponent } from './components/filter-elements/duplicate-filter/duplicate-filter.component';
import { ClinicFilterComponent } from './components/filter-elements/clinic-filter/clinic-filter.component';
import { CompanyFilterComponent } from './components/filter-elements/company-filter/company-filter.component';
import { CompanyFilterExternalComponent } from './components/filter-elements/company-filter-external/company-filter-external.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PageUnderConstructionComponent } from './components/page-under-construction/page-under-construction.component';
import { BulkConfirmComponent } from './components/today-dashboard-popup/bulk-confirm/bulk-confirm.component';
import { AutoCompleteCityStateComponent } from './components/auto-complete/auto-complete-city-state/auto-complete-city-state.component';
import { ClinicAutoCompleteComponent } from './components/form-elements/clinic-auto-complete/clinic-auto-complete.component';
import { MedicalRecordPopupComponent } from './components/medical-record-popup/medical-record-popup.component';
import { MedicationRefillRequestComponent } from './components/medication-refill-request-popup/medication-refill-request.component';
import { PharmacyUpdateRequestComponent } from './components/pharmacy-update-request-popup/pharmacy-update-request-popup.component';
import { NGAdvancePatientSearchComponent } from './components/ng-advance-patient-search-popup/ng-advance-patient-search-popup.component';
import { DateFieldComponent } from './components/form-elements/date-field/date-field.component';
import { PhoneNumberPatternPipe } from './pipes/phone-number-pattern.pipe';
import { AutoCompleteCityComponent } from './components/auto-complete/auto-complete-city/auto-complete-city.component';
import { AutoCompleteStateComponent } from './components/auto-complete/auto-complete-state/auto-complete-state.component';
import { AutoCompleteCountryComponent } from './components/auto-complete/auto-complete-country/auto-complete-country.component';

import { FileUploadModule } from 'ng2-file-upload';
import { ChartnotePopupComponent } from './components/chartnote-popup/chartnote-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RegistrationRequestPopupComponent } from './components/registration-request-popup/registration-request-popup.component';
import { DateAgoPipe } from './pipes/time-ago.pipe';
import { LoaderService } from './services/loader.service';
import { InternalLogPopupComponent } from './components/internal-log-popup/internal-log-popup.component';
import { ExternalUserRoleComponent } from './components/filter-elements/external-user-role/external-user-role.component';
import { UserActivityComponent } from './components/tables/user-activity/user-activity.component';
import { ReportsUserAccessComponent } from './components/tables/reports-user-access/reports-user-access.component';
import { UsertypeFilterComponent } from './components/filter-elements/usertype-filter/usertype-filter.component';
import { ExcelexportpopupComponent } from './components/excel-export-popup/excel-export-popup.component';
import { ViewReportsComponent } from './components/view-reports/view-reports.component';
import { HedisTableComponent } from './components/tables/hedis-table/hedis-table.component';
import { DocumentViewComponent } from './components/view-document/view-document.component';
import { UploadService } from './services/upload.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { InternalStatusFilterComponent } from './components/filter-elements/internal-status-filter/internal-status-filter.component';
import { LastHourFilterComponent } from './components/filter-elements/last-hour-filter/last-hour-filter.component';
import { TermsAndConditionsPopupComponent } from './components/terms-and-conditions-popup/terms-and-conditions-popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorPopupComponent,
    ConfirmationPopupComponent,
    NumbersDirective,
    AlphaNumericDirective,
    LoaderComponent,
    PageNotFoundComponent,
    LogoutComponent,
    HeaderInternalComponent,
    HeaderExternalComponent,
    DateRangeFilterComponent,
    DateRangePickerComponent,
    LocationFilterComponent,
    StatusFilterComponent,
    FullStatusFilterComponent,
    TransportFilterComponent,
    FormattedDatePipe,
    FormattedTimePipe,
    FileSizePipe,
    PhoneNumberComponent,
    ActionConfirmComponent,
    GenderFilterComponent,
    DatePickerComponent,
    DOBFilterComponent,
    AppointmentTableComponent,
    ReportsUserAccessComponent,
    RequestTypeFilterComponent,
    DuplicateFilterComponent,
    ClinicFilterComponent,
    PageUnderConstructionComponent,
    BulkConfirmComponent,
    ClinicAutoCompleteComponent,
    MedicalRecordPopupComponent,
    MedicationRefillRequestComponent,
    PharmacyUpdateRequestComponent,
    NGAdvancePatientSearchComponent,
    DateFieldComponent,
    AutoCompleteCityStateComponent,
    PhoneNumberPatternPipe,
    AutoCompleteCityComponent,
    AutoCompleteStateComponent,
    AutoCompleteCountryComponent,
    ChartnotePopupComponent,
    RegistrationRequestPopupComponent,
    DateAgoPipe,
    InternalLogPopupComponent,
    ExternalUserRoleComponent,
    UserActivityComponent,
    UsertypeFilterComponent,
    ExcelexportpopupComponent,
    ViewReportsComponent,
    HedisTableComponent,
   DocumentViewComponent,
   InternalStatusFilterComponent,
   LastHourFilterComponent,
   CompanyFilterComponent,
   CompanyFilterExternalComponent,
TermsAndConditionsPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ClickOutsideModule,
    MyDateRangePickerModule,
    AutocompleteLibModule,
    TextMaskModule,
    FileUploadModule,
    PdfViewerModule,
    NgSelectModule,
    ProgressBarModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxPaginationModule,
    ErrorPopupComponent,
    ConfirmationPopupComponent,
    NumbersDirective,
    AlphaNumericDirective,
    LoaderComponent,
    PageNotFoundComponent,
    LogoutComponent,
    HeaderInternalComponent,
    HeaderExternalComponent,
    ClickOutsideModule,
    DateRangeFilterComponent,
    DateRangePickerComponent,
    MyDateRangePickerModule,
    LocationFilterComponent,
    StatusFilterComponent,
    FullStatusFilterComponent,
    TransportFilterComponent,
    FormattedDatePipe,
    FormattedTimePipe,
    FileSizePipe,
    PhoneNumberComponent,
    ActionConfirmComponent,
    GenderFilterComponent,
    UsertypeFilterComponent,
    DatePickerComponent,
    DOBFilterComponent,
    AppointmentTableComponent,
    ReportsUserAccessComponent,
    RequestTypeFilterComponent,
    DuplicateFilterComponent,
    ClinicFilterComponent,
    AutocompleteLibModule,
    PageUnderConstructionComponent,
    BulkConfirmComponent,
    ClinicAutoCompleteComponent,
    MedicalRecordPopupComponent,
    MedicationRefillRequestComponent,
    PharmacyUpdateRequestComponent,
    NGAdvancePatientSearchComponent,
    DateFieldComponent,
    AutoCompleteCityStateComponent,
    PhoneNumberPatternPipe,
    TextMaskModule,
    AutoCompleteCityComponent,
    AutoCompleteStateComponent,
    AutoCompleteCountryComponent,
    FileUploadModule,
    ChartnotePopupComponent,
    PdfViewerModule,
    RegistrationRequestPopupComponent,
    DateAgoPipe,
    InternalLogPopupComponent,
    ExternalUserRoleComponent,
    UserActivityComponent,
    ExcelexportpopupComponent,
    ViewReportsComponent,
    HedisTableComponent,
    DocumentViewComponent,
    NgSelectModule,
    InternalStatusFilterComponent,
    ProgressBarModule,
    LastHourFilterComponent,
    CompanyFilterComponent,
    CompanyFilterExternalComponent,
    TermsAndConditionsPopupComponent
  ],
  providers: [
    ApiService,
    ApiUrlConstants,
    ObjectConstants,
    DatePipe,
    LoaderService,
    UploadService
  ]
})
export class SharedModule { }
