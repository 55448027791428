import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html'
})
export class DateRangeFilterComponent implements OnInit {
  @Input() dateFilterData;
  @Output() dateFilterOutput = new EventEmitter();

  public showDatepicker = false;
  public datePickerData;
  constructor() { }

  ngOnInit() {
    this.datePickerData = this.dateFilterData;
  }
  /* fn for toggling the date picker */
  public toggleDatePicker() {
    this.showDatepicker = !this.showDatepicker;
  }
  /* output event from date picker component */
  public eventFromDatePicker(event) {
    this.showDatepicker = false;
    this.dateFilterOutput.next(event);
  }
}
