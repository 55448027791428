import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderCount = 0;
  private loading = false;

  constructor() { }

  public startLoader() {
    this.loaderCount++;
  }
  public stopLoader() {
    if (this.loaderCount <= 0) {
      this.resetLoader();
    }
    this.loaderCount--;
  }
  public loaderValue() {
    this.loading = this.loaderCount ? true : false;
    return this.loading;
  }
  public resetLoader() {
    this.loaderCount = 0;
  }
}
