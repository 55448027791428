import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiService, CommonService, ApiUrlConstants, MESSAGE_TEXT } from '../../utils/imports';


@Component({
  selector: 'app-internal-log-popup',
  templateUrl: './internal-log-popup.component.html'
})
export class InternalLogPopupComponent implements OnInit {
  @Input() actionInput;
  @Output() actionOutput = new EventEmitter();
  public loading = false;
  public toasterData;
  public showToaster = false;
  departmentList = [];
  public selectedItem = {
    ID: '',
    Name: 'Select'
  };
  public deptListShow;
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService
  ) { }

  ngOnInit() {
    this.getListingDept();
  }


  /* Function for get API reasons */
  public getListingDept() {
    const url = ApiUrlConstants.URL.ShowListingLogPopURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.departmentList = response.Data;
      }
    }, (error) => {
    });
  }

  dropdownSelect(item) {
    this.selectedItem = item;
    this.deptListShow = false;
  }
  /* Function for toggle the status dropdown */
  public toggleStatusDropdown() {
    this.deptListShow = !this.deptListShow;
  }

  /* function to close the toaster */
  public closeToaster(event) {
    this.showToaster = false;
    this.actionOutput.next('close');
  }
  /* Function for update register */
  public updateDepartment() {
    const requestBody = {
      UserID: this.common.getUserID(),
      DepartmentID: this.selectedItem.ID,
      LoggedInUserID: this.common.getUserID(),
    };
    const url = ApiUrlConstants.URL.ShowInternalLogPopURL;
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.ResponseCode === 200) {
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
          message: response.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'SAVE_SUCCESS',
          showToaster: true
        };
        this.showToaster = true;
        const userDetails = this.common.getUserDetails();
        userDetails.DepartmentID = this.selectedItem.ID;
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
      }
    }, (error) => {
      this.toasterData = {
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: error.error.ResponseMessage,
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: 'API_FAILED',
        showToaster: true
      };
      this.showToaster = true;
    });
  }

}
