
import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[numberOnly]'
})
export class NumbersDirective {

    constructor(public el: ElementRef) {
        const regExp = new RegExp(/[0-9]/);

        this.el.nativeElement.onkeypress = (evt) => {
            if (!evt.ctrlKey && (evt.key.length === 1) && (evt.key.match(regExp) == null)) {
                evt.preventDefault();
            }
        };

    }
}
