import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService, ApiUrlConstants, PatternConstants } from '../../../shared/utils/imports';

@Component({
  selector: 'app-registration-request-popup',
  templateUrl: './registration-request-popup.component.html'
})
export class RegistrationRequestPopupComponent implements OnInit {
  @Output() regOutput = new EventEmitter();
  @Input() regInput;
  @Input() regInputFrom;
  public phoneNumberMask = PatternConstants.phoneNumberPattern;
  public registerForm: FormGroup;
  public regRequestList;
  public clinicData = {
    placeholder: 'Enter Clinic',
    selectedValue: '',
    selectedID: null
  };
  public loading = false;
  public selectedClinic = {
    Id: null,
    Name: ''
  };
  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly api: ApiService
  ) { }

  ngOnInit() {
    this.createForms();
    this.getRegRequest();
  }
  /* Function for closing the toaster */
  public closeConfirm(action) {
    const data = {
      action,
      actionFrom: this.regInputFrom
    };
    this.regOutput.next(data);
  }

  /* Function for creating the form elements */
  private createForms() {
    this.registerForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      clinicIDName: [''],
      clinicID: ['', Validators.required],
      location: [''],
      title: [''],
      email: [null],
      faxNumber: [''],
      phoneNumber: ['']
    });
  }

  /* Function for set form data */
  private setFormData(data) {
    this.registerForm.controls.firstName.setValue(data.FirstName);
    this.registerForm.controls.lastName.setValue(data.LastName);
    this.registerForm.controls.clinicIDName.setValue(data.ClinicNameEntered);
    this.registerForm.controls.location.setValue(data.LocationName);
    this.registerForm.controls.title.setValue(data.Title);
    this.registerForm.controls.email.setValue(data.Email);
    this.registerForm.controls.phoneNumber.setValue(data.Phone);
    this.registerForm.controls.faxNumber.setValue(data.FAX);
    this.registerForm.controls.clinicID.setValue(data.ClinicIdNew);
    this.clinicData.selectedID = data.ClinicIdNew;
    this.selectedClinic.Id = data.ClinicIdNew;
  }

  /* Function for trigger when an item is selected from clinic auto complete list */
  public clinicEvent(event) {
    this.selectedClinic = event;
    if (event) {
      this.registerForm.controls.clinicID.setValue(event.Name);
    } else {
      this.registerForm.controls.clinicID.setValue(null);
    }
  }

  /* Function for get registration request */
  public getRegRequest() {
    this.loading = true;
    const url = ApiUrlConstants.URL.registrationRequestURL;
    this.api.ExecuteGet(url + '?registerID=' + this.regInput.RequestID, (response) => {
      if (response.Data) {
        this.regRequestList = response.Data;
        this.setFormData(response.Data);
      }
    }, (error) => {

    });
  }

  /* Function for update register */
  public updateRegister() {
    const requestBody = {
      RegisterID: this.regInput.RequestID,
      ClinicID: this.selectedClinic.Id
    };
    const url = ApiUrlConstants.URL.updateRegistrationReqURL;
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.ResponseCode === 200) {
        this.loading = false;
        const data = {
          action: 'send',
          actionFrom: this.regInputFrom
        };
        this.regOutput.next(data);
      }
    }, (error) => {

    });
  }
}
