import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html'
})
export class StatusFilterComponent implements OnInit, OnChanges {
  @Input() dropdownFilterStatus;
  @Output() statusFilterOutput = new EventEmitter();
  public selectedStatus;
  public selectedValue = 'ALL';
  public statusList = [];
  public statusListCount = 0;
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getStatusList();
  }
ngOnChanges() {
  this.setStatusName();
}
  ngOnInit() {
    this.setStatusName();
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterStatus.AppointmentStatusesName = ['ALL'];
    this.dropdownFilterStatus.AppointmentStatusesKey = [0];
    this.statusFilterOutput.next('clear');
    this.setStatusName();
  }
  public setStatusName(): void {
    if (this.dropdownFilterStatus &&
      this.dropdownFilterStatus.AppointmentStatusesName &&
      this.dropdownFilterStatus.AppointmentStatusesName.length) {
      this.selectedStatus = '';
      if (this.dropdownFilterStatus.AppointmentStatusesName.length === this.statusListCount) {
        this.selectedStatus = 'ALL';
      } else {
        this.dropdownFilterStatus.AppointmentStatusesName.forEach((element, index) => {
          if (index === 0) {
            this.selectedStatus = this.selectedStatus + element;
          } else {
            this.selectedStatus = this.selectedStatus + ',' + element;
          }
        });
      }
    }
  }
  public fetchStatusKey(itemKey): boolean {
    // tslint:disable-next-line: max-line-length
    if (this.dropdownFilterStatus && this.dropdownFilterStatus.AppointmentStatusesKey && this.dropdownFilterStatus.AppointmentStatusesKey.length) {
      return (this.dropdownFilterStatus.AppointmentStatusesKey.includes(itemKey));
    }
    return false;
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    const allitem = this.dropdownFilterStatus.AppointmentStatusesKey.filter(data => data === 0);
    const existItem = this.dropdownFilterStatus.AppointmentStatusesKey.filter(data => data === item.AppointmentStatusesKey);

    if (existItem.length) {
      // tslint:disable-next-line: max-line-length
      this.dropdownFilterStatus.AppointmentStatusesName = this.dropdownFilterStatus.AppointmentStatusesName.filter(data => data !== item.AppointmentStatusesName);
      // tslint:disable-next-line: max-line-length
      this.dropdownFilterStatus.AppointmentStatusesKey = this.dropdownFilterStatus.AppointmentStatusesKey.filter(data => data !== item.AppointmentStatusesKey);
    } else {
      // tslint:disable-next-line: max-line-length
      this.dropdownFilterStatus.AppointmentStatusesName[this.dropdownFilterStatus.AppointmentStatusesName.length] = item.AppointmentStatusesName;
      // tslint:disable-next-line: max-line-length
      this.dropdownFilterStatus.AppointmentStatusesKey[this.dropdownFilterStatus.AppointmentStatusesKey.length] = item.AppointmentStatusesKey;
    }
    if ((allitem.length > 0) && (this.dropdownFilterStatus.AppointmentStatusesKey.length > 1)) {
      this.dropdownFilterStatus.AppointmentStatusesName = this.dropdownFilterStatus.AppointmentStatusesName.filter(data => data !== 'ALL');
      this.dropdownFilterStatus.AppointmentStatusesKey = this.dropdownFilterStatus.AppointmentStatusesKey.filter(data => data !== 0);
    }
    if (!this.dropdownFilterStatus.AppointmentStatusesKey.length) {
      this.dropdownFilterStatus.AppointmentStatusesName.push('ALL');
      this.dropdownFilterStatus.AppointmentStatusesKey.push(0);
    }
    this.setStatusName();
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterStatus.show = false;
  }
  /* get the list of status */
  private getStatusList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.appointmentStatusURL;
    this.statusList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.statusList = response.Data;
        this.statusListCount = this.statusList.length - 1;
        this.filterOutPending();
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }

  /* fn to filter out the pending status */
  private filterOutPending() {
    this.statusList = this.statusList.filter((element) => {
      if (this.dropdownFilterStatus.filterPending && element.AppointmentStatusesName.toLowerCase() === 'pending') {
        return false;
      } else {
        return true;
      }
    });
  }
}
