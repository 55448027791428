import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import {
  CommonService,
  MESSAGE_TEXT,
  ApiService,
  ApiUrlConstants,
  PatternConstants,
  LoaderService,
} from "../../../shared/utils/imports";
import { DateValidator } from "../../../shared/validators/date-validator";
import { Observable } from "rxjs";
@Component({
  selector: "app-ng-advance-patient-search-popup",
  templateUrl: "./ng-advance-patient-search-popup.component.html",
})
export class NGAdvancePatientSearchComponent implements OnInit {
  // @Input() NGPatientAdvanceSearchInput;
  @Output() NGPatientAdvanceSearchOutput = new EventEmitter();
  public ngadvancepatientsearchform: FormGroup;
  public phoneNumberMask = PatternConstants.phoneNumberPattern;
  public showTextBox: boolean = false;
  public dateData = {
    label: "DOB",
    dateJsValue: null,
    dateValue: { year: null, month: null, day: null },
    id: "",
  };
  public loading = true;
  public medicalRecordTypeList = [];
  public medicationsList = [];
  public companyLists = [];
  public primaryCareList = [];
  public userType;
  public isCompanyDisplay = 0;
  public isChenMed = 0;
  public sendingMethod = "Email";
  public formSubmit = false;
  public patientDOBSearch = "";
  public isReadOnly = false;
  public pharmacyField = {
    show: false,
    PharmacyName: "Select Pharmacy",
    PharmacyID: null,
    PharmacyPhone: "",
    PharmacyFax: "",
    PharmacyAddress: "",
  };
  public companyField = {
    show: false,
    CompanyName: "Select Company",
    CompanyId: null,
  };
  public searchRequest = {
    PageNumber: 1,
    PageSize: 10,
    PatientFirstName: "",
    PatientLastName: "",
    PatientDOB: "",
    PatientGender: "",
    PatientNumber: "",
    PatientAddress: "",
    PatientCityOrState: null,
    PatientZIP: "",
    User: this.common.getUserID(),
    IsAdvancePatientSearch: true,
  };
  public toasterData;
  public confirmData;
  public confirmMessage = "";
  public showToaster = false;
  public showConfirm = false;
  public dateMask: any[] = [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public medicalRecordDate = "";
  public isDateEmpty = true;
  public isEmailAndFaxEmpty = true;
  public invalidDate = false;
  public pharmacyList = [];
  public patientPCPID = 0;
  public actualPharmacy = null;
  public pharmacy = null;
  public patientPCPName;
  public patientPharmacyPhone;
  public patientPharmacyFax;
  public patientPharmacyAddress;
  public showPharmacyList;
  public existsInSameClinic;
  public patientsList = [];
  public multiplePatients = false;
  public searchCount = 0;
  public itemsPer = {
    count: 0,
    totalItems: 0,
  };
  constructor(
    private readonly api: ApiService,
    private readonly datepipe: DatePipe,
    private readonly common: CommonService,
    private readonly formBuilder: FormBuilder,
    public readonly loader: LoaderService
  ) {}

  ngOnInit() {
    this.createForms();
    this.showPharmacyList = 0;
    this.userType = this.common.getUserType();
    this.getPrimaryCareList();
  }

  /* Function for event emitter from dob filter component */
  public dobFilterEvent(event) {
    if (event === "clear") {
    }
  }

  public createForms() {
    this.ngadvancepatientsearchform = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      dob: [null, [Validators.required, DateValidator.dateVaidator]],
      primaryCareProvider: [null],
      address: [null],
    });
  }

  /* Function for toggle dropdown */
  public toggleDropdown() {
    this.pharmacyField.show = !this.pharmacyField.show;
  }
  public toggleCompanyDropdown() {
    this.companyField.show = !this.companyField.show;
  }

  /* Function for hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.pharmacyField.show = false;
  }
  public dropdownCompanyClickOutside(event) {
    this.companyField.show = false;
  }
  public clearEmailFax(event) {
    this.ngadvancepatientsearchform.controls.pharmacyphone.reset();
    this.ngadvancepatientsearchform.controls.pharmacyfaxNumber.reset();
  }

  /* Function for trigger when selecting any value in dropdown */
  public typeDropdownSelect(item) {
    this.pharmacyField.PharmacyName = item.PharmacyName;
    this.pharmacyField.PharmacyID = item.PharmacyID;
  }
  public typeCompanyDropdownSelect(item) {
    this.companyField.CompanyId = item.CompanyId;
    this.companyField.CompanyName = item.CompanyName;
  }

  /* Function for clear reason */
  public clearReason() {
    this.pharmacyField.PharmacyName = "Select Pharmacy";
    this.pharmacyField.PharmacyID = null;
    this.pharmacyField.show = false;
  }
  public clearCompany() {
    this.companyField.CompanyName = "Select Company";
    this.companyField.CompanyId = null;
    this.companyField.show = false;
  }

  /* Function for close confirm dialog */
  public closeConfirm(action, confirmMessage) {
    if (confirmMessage == "createnew") {
      this.NGPatientAdvanceSearchOutput.next("createnew");
    } else if (
      action.type === "CONFIRM_PCP_UPDATE" &&
      action.confirmAction === "confirm"
    ) {
      this.showConfirm = false;
    } else if (
      action.type === "PATIENT_NOT_EXISTS" &&
      action.confirmAction === "confirm"
    ) {
      this.NGPatientAdvanceSearchOutput.next("createnew");
    } else if (
      action.type === "PATIENT_NOT_EXISTS" &&
      action.confirmAction === "cancel"
    ) {
      this.showConfirm = false;
    } else {
      this.NGPatientAdvanceSearchOutput.next(false);
    }
  }

  /* Function for serach exact patient click */
  public advancePatientSearchClick() {
    if (this.ngadvancepatientsearchform.valid) {
      this.loader.startLoader();
      const url = ApiUrlConstants.URL.patientRecordsURL;
      this.searchRequest.PatientFirstName =
        this.ngadvancepatientsearchform.value.firstName.trim();
      this.searchRequest.PatientLastName =
        this.ngadvancepatientsearchform.value.lastName.trim();
      this.searchRequest.PatientDOB = this.setDateFormat();
      const requestBody = this.searchRequest;
      this.api.ExecutePost(
        url,
        requestBody,
        (response) => {
          if (response.ResponseCode === 200) {
            this.patientsList = response.Data.response;
            this.existsInSameClinic = response.Data.ExistsInDB;
            this.itemsPer.totalItems = response.Data.response[0].TotalRecords;
            this.itemsPer.totalItems <= this.patientsList.length
              ? (this.itemsPer.count = this.itemsPer.totalItems)
              : (this.itemsPer.count = this.patientsList.length);
          }

          if (this.existsInSameClinic === 1) {
            this.itemsPer.count = 0;
            this.loader.stopLoader();
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
              message: "The patient exists in this PCP. Please search again.",
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: "REQUEST_SUCCESS",
              showToaster: true,
            };
            this.showToaster = true;
          } else if (this.existsInSameClinic === 2) {
            this.confirmData = {
              heading: MESSAGE_TEXT.CONFIRM_HEADING,
              message: MESSAGE_TEXT.PCP_UPDATE_ALERT,
              delete: "PCPUPDATE",
              leftButton: "Cancel",
              rightButton: MESSAGE_TEXT.CONFIRM_PROCEED,
              type: "CONFIRM_PCP_UPDATE",
              confirmAction: "",
            };
            this.multiplePatients = true;
            this.showConfirm = true;
            this.loader.stopLoader();
            this.showToaster = false;
            this.disableSearchEntry();
          } else {
            var patientexistsmessage = "";
            if (this.itemsPer.count > 1 || this.multiplePatients === true)
              patientexistsmessage = MESSAGE_TEXT.PATIENT_EXIST_ALERT_ADDRESS;
            else patientexistsmessage = MESSAGE_TEXT.PATIENT_EXIST_ALERT;

            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
              message: patientexistsmessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: "PATIENT_FOUND",
              showToaster: true,
            };
            this.showToaster = true;
            this.disableSearchEntry();
            this.loader.stopLoader();
          }
        },
        (error) => {
          this.itemsPer.count = 0;
          this.loader.stopLoader();
          this.confirmData = {
            heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
            message: MESSAGE_TEXT.PATIENT_NOT_EXIST_ALERT,
            delete: "PCPUPDATE",
            leftButton: "Cancel",
            rightButton: MESSAGE_TEXT.CONFIRM_PROCEED,
            type: "PATIENT_NOT_EXISTS",
            confirmAction: "",
            // ,
            // showConfirm: true
            // showToaster: true
          };
          // this.toasterData = {
          //   heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          //   message: MESSAGE_TEXT.PATIENT_NOT_EXIST_ALERT,
          //   buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          //   type: 'PATIENT_NOT_EXISTS',
          //   showToaster: true
          // };
          this.showConfirm = true;
          // this.showToaster = true;
        }
      );
    } else {
      this.itemsPer.count = 0;
      this.toasterData = {
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: "Please enter values for all required fields",
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: "PATIENT_CREATE_FAIL",
        showToaster: true,
      };
      this.showToaster = true;
    }
  }

  public disableSearchEntry() {
    this.ngadvancepatientsearchform.controls["firstName"].disable();
    this.ngadvancepatientsearchform.controls["lastName"].disable();
    this.ngadvancepatientsearchform.controls["dob"].disable();
  }

  /* Fn for PCP API list */
  public getPrimaryCareList() {
    this.loader.startLoader();
    this.primaryCareList = [];
    let url = ApiUrlConstants.URL.pcpListURL;
    url = url.replace("CLINIC_ID", this.common.getUserClinicID());
    if (this.userType === "External") {
      url = url.replace("IS_EXTERNAL", "true");
    } else {
      url = url.replace("IS_EXTERNAL", "false");
    }
    this.api.ExecuteGet(
      url,
      (response) => {
        if (response.Data && response.Data.length) {
          this.primaryCareList = response.Data;
        }
        this.loader.stopLoader();
      },
      (error) => {
        this.loader.stopLoader();
      }
    );
  }

  /* Function for PCP update request click */
  public pcpUpdateRequestClick() {
    if (this.ngadvancepatientsearchform.valid) {
      this.loader.startLoader();
      if (this.multiplePatients) this.searchCount = 1;
      const url = ApiUrlConstants.URL.updatePCPMailRequest;
      const requestBody = {
        PatientFname:
          this.ngadvancepatientsearchform.controls["firstName"].value.trim(),
        PatientLname:
          this.ngadvancepatientsearchform.controls["lastName"].value.trim(),
        PatientDOB: this.ngadvancepatientsearchform.controls["dob"].value,
        PCPName: this.patientPCPName,
        PCPId: this.patientPCPID,
        PatientAddress:
          this.ngadvancepatientsearchform.controls["address"].value,
        User: this.common.getUserID(),
        SearchCount: this.searchCount,
      };
      this.api.ExecutePost(
        url,
        requestBody,
        (response) => {
          if (response.ResponseCode === 200) {
            this.loader.stopLoader();
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
              message: response.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: "REQUEST_SUCCESS",
              showToaster: true,
            };
            this.showToaster = true;
          }
        },
        (error) => {
          this.loader.stopLoader();
          this.toasterData = {
            heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
            message: error.error.ResponseMessage,
            buttonText: MESSAGE_TEXT.ALERT_BUTTON,
            type: "PATIENT_UPDATE_FAIL",
            showToaster: true,
          };
          this.showToaster = true;
        }
      );
    } else {
      this.toasterData = {
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: "Please enter values for all required fields",
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: "PATIENT_CREATE_FAIL",
        showToaster: true,
      };
      this.showToaster = true;
    }
  }

  /* Function for close the toaster */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    this.showConfirm = event.showConfirm;
    if (event.type === "REQUEST_SUCCESS") {
      this.closeConfirm(event, "send");
    } else if (event.type === "PATIENT_NOT_EXISTS") {
      this.closeConfirm(event, "createnew");
    }
  }
  /* function for check all fields valid */
  public disableAdvanceSearchButton() {
    if (
      this.ngadvancepatientsearchform.value.firstName == null ||
      this.ngadvancepatientsearchform.value.lastName == null ||
      this.ngadvancepatientsearchform.value.dob == null ||
      this.invalidDate == true
    ) {
      return true;
    } else {
      if (
        this.ngadvancepatientsearchform.value.firstName.trim() == "" ||
        this.ngadvancepatientsearchform.value.lastName.trim() == "" ||
        this.ngadvancepatientsearchform.value.dob == null ||
        !this.ngadvancepatientsearchform.valid ||
        this.invalidDate != false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  /* function for enable request send button */
  public disableRequestSendButton() {
    if (
      this.itemsPer.count <= 1 &&
      this.multiplePatients == false &&
      (this.ngadvancepatientsearchform.controls["firstName"].value == "" ||
        this.ngadvancepatientsearchform.controls["lastName"].value == "" ||
        this.ngadvancepatientsearchform.controls["dob"].value == null ||
        this.patientPCPID == 0 ||
        this.patientPCPID == null ||
        !this.ngadvancepatientsearchform.valid)
    ) {
      this.searchCount = 0;
      return true;
    } else if (
      this.itemsPer.count == 1 &&
      this.multiplePatients == true &&
      (this.ngadvancepatientsearchform.controls["firstName"].value == "" ||
        this.ngadvancepatientsearchform.controls["lastName"].value == "" ||
        this.ngadvancepatientsearchform.controls["dob"].value == null ||
        this.patientPCPID == 0 ||
        this.patientPCPID == null ||
        this.ngadvancepatientsearchform.value.address == null ||
        this.ngadvancepatientsearchform.value.address.trim() == "" ||
        !this.ngadvancepatientsearchform.valid)
    ) {
      this.searchCount = 1;
      return true;
    } else if (
      this.itemsPer.count > 1 &&
      (this.ngadvancepatientsearchform.controls["firstName"].value == "" ||
        this.ngadvancepatientsearchform.controls["lastName"].value == "" ||
        this.ngadvancepatientsearchform.controls["dob"].value == null ||
        this.patientPCPID == 0 ||
        this.patientPCPID == null ||
        this.ngadvancepatientsearchform.value.address == null ||
        this.ngadvancepatientsearchform.value.address.trim() == "" ||
        !this.ngadvancepatientsearchform.valid)
    ) {
      this.searchCount = 1;
      return true;
    } else {
      return false;
    }
  }

  onCheckboxChange() {
    this.showTextBox = !this.showTextBox;
  }

  /* change PCP value fn */
  public changePCP() {
    if (this.ngadvancepatientsearchform.value.primaryCareProvider) {
      this.primaryCareList.forEach((element) => {
        if (
          element.ID ===
          this.ngadvancepatientsearchform.value.primaryCareProvider
        ) {
          this.patientPCPID = element.ID;
          this.patientPCPName = element.Name;
          this.ngadvancepatientsearchform
            .get("primaryCareProvider")
            .setValue(this.patientPCPID);
        }
      });
    } else {
      this.patientPCPID = 0;
      this.patientPCPName = "Not Updated ";
    }
  }

  public dateChange() {
    this.isDateEmpty = false;
    if (!this.ngadvancepatientsearchform.get("dob").hasError("dateVaidator")) {
      const dateValue = this.ngadvancepatientsearchform.value.dob;
      if (dateValue != null) {
        const formattedDate =
          dateValue.split("/")[2] +
          "-" +
          dateValue.split("/")[0] +
          "-" +
          dateValue.split("/")[1];
        this.invalidDate = this.checkDate(new Date(formattedDate));
      }
    }
  }
  public setDateFormat() {
    const dateValue = this.ngadvancepatientsearchform.value.dob;
    const formattedDate =
      dateValue.split("/")[2] +
      "-" +
      dateValue.split("/")[0] +
      "-" +
      dateValue.split("/")[1];
    if (this.common.checkForAPIDate(formattedDate)) {
      return formattedDate;
    } else {
      return "";
    }
  }

  // public checkDate(date1) {
  //   const d1 = new Date(date1);
  //   if (d1.getFullYear() < 1753) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  public checkDate(date1) {
    const d1 = new Date(date1);
    const d2 = new Date();
    if (d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
      return true;
    } else {
      if (d1 > d2) {
        return true;
      } else {
        if (d1.getFullYear() < 1753) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  //   /** Function for close the confirm popup
  //  * @param event output event
  //  */
  //   public closeConfirm(event) {
  //     this.showConfirm = false;
  //     if (event.type === 'CONFIRM_DELETE_NOTIFICATION' && event.confirmAction === 'confirm') {
  //       this.sendNotification();
  //     }
  //   }
}
