import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html'
})
export class LocationFilterComponent implements OnInit {
  @Input() dropdownFilterLocation;
  @Output() locationFilterOutput = new EventEmitter();
  public showList;
  public locationsList = [];
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getLocationsList();
  }

  ngOnInit() {
    this.showList = this.dropdownFilterLocation.show;
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterLocation.LocationsName = 'ALL';
    this.dropdownFilterLocation.LocationsKey = 0;
    this.locationFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterLocation.LocationsName = item.LocationsName;
    this.dropdownFilterLocation.LocationsKey = item.LocationsKey;
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterLocation.show = false;
  }
  /* get the list of locations */
  private getLocationsList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.externallocationsURL;
    this.locationsList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.locationsList = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }
}
