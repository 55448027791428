import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { CommonService, MESSAGE_TEXT, ApiService, ApiUrlConstants, PatternConstants, LoaderService } from '../../../shared/utils/imports';
import { DateValidator } from '../../../shared/validators/date-validator';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-pharmacy-update-request-popup',
  templateUrl: './pharmacy-update-request-popup.component.html'
})
export class PharmacyUpdateRequestComponent implements OnInit {
  @Input() PharmacyUpdateRequestInput;
  @Output() PharmacyUpdateRequestOutput = new EventEmitter();
  public pharmacyupdaterequestform: FormGroup;
  public phoneNumberMask = PatternConstants.phoneNumberPattern;
  public showTextBox: boolean = false;
  public dateData = {
    label: 'DOB',
    dateJsValue: null,
    dateValue: { year: null, month: null, day: null },
    id: ''
  };
  public loading = true;
  public medicalRecordTypeList = [];
  public medicationsList = [];
  public companyLists = [];
  public isCompanyDisplay = 0;
  public isChenMed = 0;
  public sendingMethod = 'Email';
  public formSubmit = false;
  public pharmacyField = {
    show: false,
    PharmacyName: 'Select Pharmacy',
    PharmacyID: null,
    PharmacyPhone:'',
    PharmacyFax:'',
    PharmacyAddress:''
  };
  public companyField = {
    show: false,
    CompanyName: 'Select Company',
    CompanyId: null
  };
  public toasterData;
  public showToaster = false;
  public dateMask: any[] = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public medicalRecordDate = '';
  public isDateEmpty = true;
  public isEmailAndFaxEmpty=true;
  public invalidDate = false;
  public pharmacyList=[];
  public patientPharmacyID=null;
  public actualPharmacy=null;
  public pharmacy=null;
  public patientPharmacyName;
  public patientPharmacyPhone;
  public patientPharmacyFax;
  public patientPharmacyAddress;
  public showPharmacyList;
  constructor(
    private readonly api: ApiService,
    private readonly datepipe: DatePipe,
    private readonly common: CommonService,
    private readonly formBuilder: FormBuilder,
    public readonly loader: LoaderService
  ) {
  }

  ngOnInit() {
     this.getPharmacyByPatientId();
     this.getPharmacyList();
    this.createForms();
    this.showPharmacyList =0;
  }

  /* Function for event emitter from dob filter component */
  public dobFilterEvent(event) {
    if (event === 'clear') {
    }
  }

  public createForms() {
    this.pharmacyupdaterequestform = this.formBuilder.group({
        pharmacy: [''],
        pharmacyname:[null],
        pharmacyphone: [null, [Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
        pharmacyfaxNumber : [null, [Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
        pharmacyaddress: ''
    })
  }

  /* Function for toggle dropdown */
  public toggleDropdown() {
    this.pharmacyField.show = !this.pharmacyField.show;
  }
  public toggleCompanyDropdown() {
    this.companyField.show = !this.companyField.show;
  }

  /* Function for hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.pharmacyField.show = false;
  }
  public dropdownCompanyClickOutside(event) {
    this.companyField.show = false;
  }
  public clearEmailFax(event)
  {
    this.pharmacyupdaterequestform.controls.pharmacyphone.reset();
    this.pharmacyupdaterequestform.controls.pharmacyfaxNumber.reset();
  }

  /* Function for trigger when selecting any value in dropdown */
  public typeDropdownSelect(item) {
    this.pharmacyField.PharmacyName = item.PharmacyName;
    this.pharmacyField.PharmacyID = item.PharmacyID;
  }
  public typeCompanyDropdownSelect(item) {
    this.companyField.CompanyId = item.CompanyId;
    this.companyField.CompanyName = item.CompanyName;
  }

  /* Function for clear reason */
  public clearReason() {
    this.pharmacyField.PharmacyName = 'Select Pharmacy';
    this.pharmacyField.PharmacyID = null;
    this.pharmacyField.show = false;
  }
  public clearCompany() {
    this.companyField.CompanyName = 'Select Company';
    this.companyField.CompanyId = null;
    this.companyField.show = false;
  }

  /* Function for close confirm dialog */
  public closeConfirm(action) {
    this.PharmacyUpdateRequestOutput.next(false);
  }

  /* Function for request pharmacy click */
  public requestPharmacyClick() {
    if (this.pharmacyupdaterequestform.valid) {
      if(this.showTextBox === true)
      {
        this.pharmacy = 0;
      }
      else{
        this.pharmacy = this.pharmacyupdaterequestform.value.pharmacy;
      }
      if(this.actualPharmacy === this.pharmacy)
      {
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          message: 'The selected pharmacy is the same as the current one',
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'PATIENT_CREATE_FAIL',
          showToaster: true
        };
        this.showToaster = true;
      }
      else if(this.PharmacyUpdateRequestInput.lastRequestedPharmacy===this.pharmacy)
      {
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          message: 'This is a duplicate pharmacy update request',
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'PATIENT_CREATE_FAIL',
          showToaster: true
        };
        this.showToaster = true;
      }
      else
      {
      this.loader.startLoader();
      const url = ApiUrlConstants.URL.updatePharmacyMailRequest;
      const requestBody = {
        PatientID: this.PharmacyUpdateRequestInput.patientID,
        PharmacyID: this.pharmacy,
        PharmacyPhone: this.pharmacyupdaterequestform.value.pharmacyphone,
        PharmacyFax: this.pharmacyupdaterequestform.value.pharmacyfaxNumber,
        PharmacyAddress: this.pharmacyupdaterequestform.value.pharmacyaddress,
        IsNewPharmacy: this.showTextBox,
        PharmacyNameNew: this.pharmacyupdaterequestform.value.pharmacyname,
        User: this.common.getUserID()
      };
      this.api.ExecutePost(url, requestBody, (response) => {
        if (response.ResponseCode === 200) {
          this.loader.stopLoader();
          this.toasterData = {
            heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
            message: response.ResponseMessage,
            buttonText: MESSAGE_TEXT.ALERT_BUTTON,
            type: 'REQUEST_SUCCESS',
            showToaster: true
          };
          this.showToaster = true;
        }
      }, (error) => {
        this.loader.stopLoader();
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          message: error.error.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'PATIENT_UPDATE_FAIL',
          showToaster: true
        };
        this.showToaster = true;
      });

    }
    } else {
      this.toasterData = {
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: 'Please enter values for all required fields',
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: 'PATIENT_CREATE_FAIL',
        showToaster: true
      };
      this.showToaster = true;
    }
  }

    /* get all pharmacies list */
    public getPharmacyList() {
      //this.loader.startLoader();
      this.pharmacyList = [];
      const url = ApiUrlConstants.URL.pharmaciesListURL;
      this.api.ExecuteGet(url, (response) => {
        if (response.Data && response.Data.length) {
          this.pharmacyList = response.Data;
          //this.loader.stopLoader();
        }
      }, (error) => {
        //this.loader.stopLoader();
      });
    }

    /* get patient pharmacy */
    public getPharmacyByPatientId() {
      this.loading = true;
      this.pharmacyList = [];
      let url = ApiUrlConstants.URL.pharmacyByPatientIdURL;
      url = url.replace('PATIENT_ID', this.PharmacyUpdateRequestInput.patientID);
      this.api.ExecuteGet(url, (response) => {
          if(response.Data.name != null)
          {
            this.actualPharmacy=response.Data.key;
            this.patientPharmacyID =  response.Data.key;
            this.patientPharmacyName =  response.Data.name;
          }
          else{
            this.patientPharmacyID =  "0";
            this.patientPharmacyName =  "Not Updated";
          }
          this.setPharmacyForm();
          this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }

    public setPharmacyForm()
    {
      //this.loading = true;
      this.pharmacyupdaterequestform.get('pharmacy').setValue(this.patientPharmacyID);
      // this.showPharmacyList =0;
      //this.loading = false;
    }


  /* Function for close the toaster */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    if (event.type === 'REQUEST_SUCCESS') {
      this.closeConfirm('send');
    }
  }
/* function for check all fields valid */
  public disablePharmacyReqButton() {
if(this.showTextBox === true)
{
if(this.pharmacyupdaterequestform.value.pharmacyname == '' || this.pharmacyupdaterequestform.value.pharmacyname == null || this.pharmacyupdaterequestform.value.pharmacyphone == null || this.pharmacyupdaterequestform.value.pharmacyphone =='' || this.pharmacyupdaterequestform.value.pharmacyfaxNumber == null || this.pharmacyupdaterequestform.value.pharmacyfaxNumber ==''|| !this.pharmacyupdaterequestform.valid){
return true;
}
else {
  return false;
}
}
else{
  if (this.patientPharmacyID < 1 || this.patientPharmacyID === null || this.pharmacyupdaterequestform.value.pharmacyphone == null || this.pharmacyupdaterequestform.value.pharmacyphone =='' || this.pharmacyupdaterequestform.value.pharmacyfaxNumber == null || this.pharmacyupdaterequestform.value.pharmacyfaxNumber ==''|| !this.pharmacyupdaterequestform.valid ) {
    return true;
  }
  else {
    return false;
  }
}
  }

  onCheckboxChange() {
    this.showTextBox = !this.showTextBox;
  }

  /* change pharmacy value fn */
  public changePharmacy() {
    if (this.pharmacyupdaterequestform.value.pharmacy) {
      this.pharmacyList.forEach(element => {
        if (element.PharmacyKey === this.pharmacyupdaterequestform.value.pharmacy) {
          this.patientPharmacyID = element.PharmacyKey;
          this.patientPharmacyName = element.PharmacyName;
          this.pharmacyupdaterequestform.get('pharmacy').setValue(this.patientPharmacyID);
        }
      });
    } else {
      this.patientPharmacyID = 0;
      this.patientPharmacyName = 'Not Updated ';
    }
  }

  public dateChange() {
    this.isDateEmpty = false;
    if (!this.pharmacyupdaterequestform.get('medicalRecordDate').hasError('dateVaidator')) {
      const dateValue = this.pharmacyupdaterequestform.value.medicalRecordDate;
      const formattedDate = dateValue.split('/')[2] + '-' + dateValue.split('/')[0] + '-' + dateValue.split('/')[1];
      this.invalidDate= this.checkDate(new Date(formattedDate));
    }
  }

  public setDateFormat() {
    const dateValue = this.pharmacyupdaterequestform.value.medicalRecordDate;
    const formattedDate = dateValue.split('/')[2] + '-' + dateValue.split('/')[0] + '-' + dateValue.split('/')[1];
    if (this.common.checkForAPIDate(formattedDate)) {
      return formattedDate;
    } else {
      return '';
    }
  }
  public checkDate(date1) {
    const d1 = new Date(date1);
    if (d1.getFullYear() < 1753) {
      return true;
    } else {
      return false;
    }
  }
}
