import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants } from '../../utils/imports';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html'
})
export class ViewReportsComponent implements OnInit {
  @Output() viewReportOutput = new EventEmitter();
  @Input() viewReportInput;
  public reportsMonthlyDownloads = [];
  public totalRecords = 0;
  public pageSize = 10;
  constructor(
    private readonly api: ApiService,
  ) { }

  ngOnInit() {
    this.viewDownloadReports();

  }
  seeMore() {
    this.pageSize = this.pageSize + 10;
    this.viewDownloadReports();
  }
  /*function for view download reports */
  viewDownloadReports() {
    const url = ApiUrlConstants.URL.viewdownloadlistURL;
    const requestBody = {
      PageNumber: 1,
      PageSize: this.pageSize,
      User: this.viewReportInput.data.UserKey,
    };
    this.reportsMonthlyDownloads = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.MontlyDownloads) {
        this.reportsMonthlyDownloads = response.Data.MontlyDownloads;
        this.totalRecords = response.Data.TotalRecords;
      }
    }, (error) => {

    });
  }
  /*Function for close the popups */
  closePopup() {
    this.viewReportOutput.next(false);
  }
}
