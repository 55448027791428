import { Component, OnInit, Input } from '@angular/core';
import { ApiService, ArrayConstants, LoaderService, CommonService, ApiUrlConstants } from '../../../../shared/utils/imports';

import { Router } from '@angular/router';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html'
})

export class UserActivityComponent implements OnInit {

  @Input() tableFilter;
  public appOverviewList = [];
  public itemsPerList = ArrayConstants.itemsPerPageArray;
  public itemsPerPage = 10;
  public itemsPer = {
    count: 0,
    totalItems: 0
  };
  public sortIcons = {
    sortpatientName: '',
    sortcancelReq: '',
    sortrescheduleReq: '',
    sortappReq: '',
    sortmrReq: '',
    sortREreq: '',
    sortPRreq: '',
    sorttotalReq: '',
    sortClinic: ''
  };
  public currentPage = 1;
  public userType;
  public summary = {
    completed: 0,
    denied: 0,
    pending: 0,
    totalCount: 0
  };
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService,
    private readonly router: Router,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    this.checkUserType();
  }
  /* Function for check the type of user logged */
  public checkUserType() {
    this.userType = this.common.getUserType();
  }

  /* Function for trigger for page change event */
  public pageChange(newPage: number) {
    this.currentPage = newPage;
    this.tableFilter.requestData.PageNumber = newPage;
    this.getAppOverviewList();
  }

  /* Function for trigger when items per change value is changed */
  public itemsPerPageChange() {
    this.tableFilter.requestData.PageSize = this.itemsPerPage;
    this.tableFilter.requestData.PageNumber = 1;
    this.getAppOverviewList();
  }
  /* Function for sorting the table column */
  public sortColumn(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'Username':
        this.resetSortIcons();
        this.sortIcons.sortpatientName = sortOrder;
        break;
      case 'ClinicName':
        this.resetSortIcons();
        this.sortIcons.sortClinic = sortOrder;
        break;
    }
    this.appOverviewList = this.common.sortColumn(this.appOverviewList, columnToSort, sortOrder);
  }
  /* Function for hiding sort icon*/
  private resetSortIcons() {
    this.sortIcons = {
      sortpatientName: '',
      sortcancelReq: '',
      sortrescheduleReq: '',
      sortappReq: '',
      sortmrReq: '',
      sortREreq: '',
      sortPRreq: '',
      sorttotalReq: '',
      sortClinic: ''
    };
  }
  /* Function for sort number column */
  public sortNumber(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'CancelRequest':
        this.resetSortIcons();
        this.sortIcons.sortcancelReq = sortOrder;
        break;
      case 'RescheduleRequest':
        this.resetSortIcons();
        this.sortIcons.sortrescheduleReq = sortOrder;
        break;
      case 'AptRequest':
        this.resetSortIcons();
        this.sortIcons.sortappReq = sortOrder;
        break;
      case 'MedicalRecordsRequest':
        this.resetSortIcons();
        this.sortIcons.sortmrReq = sortOrder;
        break;
      case 'ReferalEnquiryRequest':
        this.resetSortIcons();
        this.sortIcons.sortREreq = sortOrder;
        break;
      case 'PharmacyRefillReequest':
        this.resetSortIcons();
        this.sortIcons.sortPRreq = sortOrder;
        break;
      case 'TotalRequests':
        this.resetSortIcons();
        this.sortIcons.sorttotalReq = sortOrder;
        break;
    }
    this.appOverviewList = this.common.sortNumber(this.appOverviewList, columnToSort, sortOrder);
  }

  /* Function for get the list of locations */
  public getAppOverviewList() {
    this.loader.startLoader();
    if (this.tableFilter && this.tableFilter.requestData) {
      if (this.tableFilter.requestData.PageSize) {
        this.itemsPerPage = this.tableFilter.requestData.PageSize;
      } else {
        this.itemsPerPage = 10;
      }
      this.currentPage = this.tableFilter.requestData.PageNumber;
    }

    const url = ApiUrlConstants.URL.userActivityURL;
    const requestBody = this.tableFilter.requestData;
    this.appOverviewList = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.PatientRecordsList && response.Data.PatientRecordsList.length) {

        this.appOverviewList = response.Data.PatientRecordsList;
        this.summary.totalCount = response.Data.Totalcount;

        this.itemsPer.totalItems = response.Data.TotalRecords;
        this.itemsPer.totalItems <= this.appOverviewList.length ?
          this.itemsPer.count = this.itemsPer.totalItems :
          this.itemsPer.count = this.appOverviewList.length;
        this.resetSortIcons();
      }
      if (this.userType === 'Internal') {
        this.getAppSummary();
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.itemsPer.totalItems = 0;
      this.appOverviewList = [];
    });

  }
  private getAppSummary() {
    this.loader.startLoader();
    const sumurl = ApiUrlConstants.URL.appoverviewsummaryURL;
    const summaryBody = this.tableFilter.summaryData;
    this.api.ExecutePost(sumurl, summaryBody, (response) => {
      this.summary.completed = response.Data.Completed;
      this.summary.denied = response.Data.Denied;
      this.summary.pending = response.Data.Pending;
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.summary.completed = 0;
      this.summary.denied = 0;
      this.summary.pending = 0;
    });
  }

}
