import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IMyDrpOptions, IMyDateSelected, IMyCalendarViewChanged } from 'mydaterangepicker';
import { ObjectConstants } from '../../constants/objects';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {
  @Input() datePickerInput;
  @Output() datePickerOutput = new EventEmitter();
  public today = new Date();
  public myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'dd.mm.yyyy',
    inline: true,
    monthLabels: ObjectConstants.datepickerMonths,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public dateValue: any = {
    beginDate: { year: null, month: null, day: null },
    endDate: { year: null, month: null, day: null }
  };

  constructor() { }

  ngOnInit() {
    if (this.datePickerInput.disableFuture) {
      this.myDateRangePickerOptions.disableSince = {
        year: this.today.getFullYear(),
        month: this.today.getMonth() + 1,
        day: this.today.getDate() + 1
      };
    }
    this.initializeDate();
  }
  /* fn for highlighting the already selected dates in calender */
  private initializeDate() {
    this.dateValue.beginDate = this.datePickerInput.dateValue;
    this.dateValue.beginJsDate = this.datePickerInput.dateJsValue;
  }

  /*
   * fn for clearing the date fields
   * clear the ng model value and clear the two way binding value
   */
  public clearDates() {
    this.dateValue = '';
    this.datePickerInput.dateJsValue = '';
    this.datePickerInput.dateValue = { year: null, month: null, day: null };
    this.datePickerOutput.next('clear');
  }
  /** fn triggered on selection of dates
   * @param event output event
   */
  public onDateSelected(event: IMyDateSelected) {
    this.dateValue = {};
    this.dateValue.beginDate = event.date;
    this.dateValue.beginJsDate = event.jsdate;
    this.datePickerInput.dateValue = event.date;
    this.datePickerInput.dateJsValue = event.jsdate;
    this.datePickerOutput.next(event);
  }

  /** hide the datepicker on clicking outside
   * @param event output event
   */
  public datePickerClickOutside(event) {
    this.closeDatePicker();
  }

  /* close the datepicker */
  public closeDatePicker() {
    this.datePickerOutput.next('close');
  }
}
