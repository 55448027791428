import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }
  /**
   * Service for setting the file item paramters
   * @param fileElement is each element file inside the uploader queue array
   * @param fileIndex is the index of each file element
   * set the default value for progress as 0
   * set the status of error case as false
   */
  public setUploadParameters(fileElement, fileIndex , fileDetails) {
    fileElement.progress = 0;
    fileElement.index = fileIndex;
    fileElement.isError = false;
    fileElement.request = null;
    fileElement.fileDetails = fileDetails;
    return fileElement;
  }
  /**
   * Function which divides the file element into chunks  
   * @param element is the file element to be divided
   * if the file size is greater than 50 mb then chunk size is 10 mb
   * else the chunk size is 10 mb
   */
  public getUploadChunks(element, chunksCallback) {
    const fileSize = element._file.size;
    let chunkSize = 0;
    /* if size greater than 20 MB , chunk size is 10 MB */
    /* else chunk size is 5 MB */
    if (fileSize > (20 * 1024 * 1024)) {
      chunkSize = 10 * 1024;
    } else {
      chunkSize = 5 * 1024;
    }
    const maxFileSizeKB = chunkSize;
    const fileChunks = [];
    const bufferChunkSizeInBytes = maxFileSizeKB * 1024;

    let currentStreamPosition = 0;
    let endPosition = bufferChunkSizeInBytes;
    while (currentStreamPosition < fileSize) {
      fileChunks.push(element._file.slice(currentStreamPosition, endPosition));
      currentStreamPosition = endPosition;
      endPosition = currentStreamPosition + bufferChunkSizeInBytes;
    }
    chunksCallback(fileChunks);
  }
  /**
   * Function to add % in to progress number
   * @param progressValue is the progress value
   */
  public getProgressValue(progressValue) {
    return progressValue ? progressValue + '%' : '0%';
  }
}
