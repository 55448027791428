import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/utils/imports';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  @Output() logoutEvent = new EventEmitter();
  constructor(
    private readonly router: Router,
    private readonly common: CommonService
  ) { }

  ngOnInit() {
  }

  /* Function for close the toaster */
  public closePopup() {
    this.common.userLogout();
    this.router.navigate(['login']);
  }

  /* Function for cancel logout */
  public cancelLogout() {
    this.logoutEvent.next(false);
  }
}
