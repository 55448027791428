import { Component } from '@angular/core';
import { ApiService, CommonService, ApiUrlConstants } from './shared/utils/imports';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public toasterData;
  public showToaster;
  public RefreshTimeOUT;
  // tslint:disable-next-line: ban-types
  public idleTimedOut: Boolean = false;
  constructor(private readonly api: ApiService, private readonly common: CommonService,
              private readonly router: Router, private idle: Idle) {
    this.common.timeoutCheck.subscribe(value => {
      if (value) {
        this.toasterData = {
          heading: 'SESSION TIMEOUT',
          // message: '',
          buttonText: 'OK',
          type: 'TIMEOUT',
          showToaster: true
        };
        this.showToaster = true;
        this.common.setTimeoutValue(false);
      }

    });

    this.common.userTypeCheck.subscribe(value => {
      const refreshToken = this.common.getRefreshToken();
      if (value === 'Internal') {
        this.idle.setIdle(3540);
        this.idlefunction();
        if (refreshToken) {
          this.getNewAPIToken();
          if (this.RefreshTimeOUT) {
            clearTimeout(this.RefreshTimeOUT);
          }
          this.RefreshTimeOUT = setInterval(() => {
            this.getNewAPIToken();
          }, 3600000);
        }
      } else if (value === 'External') {
        this.idle.setIdle(900);
        this.idlefunction();
        if (refreshToken) {
          this.getNewAPIToken();
          if (this.RefreshTimeOUT) {
            clearTimeout(this.RefreshTimeOUT);
          }
          this.RefreshTimeOUT = setInterval(() => {
            this.getNewAPIToken();
          }, 960000);
        }
      }
    });

    /*Idle / Session timeout*/

  }

  idlefunction() {
    // this.idle.setIdle(1140); /* 19 minutes = 1140 seconds*/
    this.idle.setTimeout(0); /* 300 second default */
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {

    });

    this.idle.onTimeout.subscribe(() => {

      this.idleTimedOut = true;
    });

    this.idle.onIdleStart.subscribe(() => {
      console.log('session time out ' + new Date());
      this.sessionTimeout();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {

    });

    this.reset();
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    /* call the new token api at every 20 minutes */
    // const refreshToken = this.common.getRefreshToken();
    // if (refreshToken) {
    //   this.getNewAPIToken();
    //   if (this.RefreshTimeOUT) {
    //     clearTimeout(this.RefreshTimeOUT);
    //   }
    //   this.RefreshTimeOUT = setInterval(() => {
    //     this.getNewAPIToken();
    //   }, 780000);
    // }
    
    this.common.signalrRefreshSource.next(true);
  }

  sessionTimeout() {
    console.log('Idle works');
    this.common.setTimeoutValue(true);
    sessionStorage.clear();
    // this.router.navigate(['login']);
    // this.router.navigate(['./login']);
    this.router.navigate(['../login']);
  }

  reset() {
    this.idle.watch();
    this.idleTimedOut = false;
  }

  /** Function for close the toaster
   * @param event output event
   */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    if (event.type === 'TIMEOUT') {
      this.common.setTimeoutValue(false);
    }
  }

  /* Function for get new api token */
  public getNewAPIToken() {
    const refreshToken = this.common.getRefreshToken();
    if (refreshToken) {
      const url = ApiUrlConstants.URL.receiveRefreshTokenURL + '?refreshtoken=' + refreshToken;
      this.api.ExecuteGetRefresh(url, (response) => {
        if (response.ResponseCode === 200) {
          sessionStorage.setItem('userToken', JSON.stringify(response.Data.AccessToken));
          sessionStorage.setItem('refreshToken', JSON.stringify(response.Data.RefreshToken));
        }
      }, (error) => {

      });
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this.idle.stop();
  }


}
