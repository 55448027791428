import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class DataService{

    chartNoteData : any;
    appointmentHistoryData : any;
    constructor() {}
}