import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor() { }

  /**
   * function for checking whether the given string includes any special character or not
   * @param fieldValue = value to be tested
   * @returns true if it includes special character
   * @returns returns false if it doesnot includes special character
   */
  public checkForSymbol(fieldValue) {
    const format = new RegExp('[!@#$%^&+=\*]');
    if (fieldValue && format.test(fieldValue)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * function for checking whether the given string includes any number or not
   * @param fieldValue = value to be tested
   * @returns true if it includes number
   * @returns returns false if it doesnot includes number
   */
  public checkForNumber(fieldValue) {
    const format = new RegExp(/\d/);
    if (fieldValue && format.test(fieldValue)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * function for checking whether the given string includes any lower case char or not
   * @param fieldValue = value to be tested
   * @returns true if it includes lower case char
   * @returns returns false if it doesnot includes lower case char
   */
  public checkForLowercase(fieldValue) {
    if (fieldValue && (fieldValue !== fieldValue.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * function for checking whether the given string includes any upper case char or not
   * @param fieldValue = value to be tested
   * @returns true if it includes upper case char
   * @returns returns false if it doesnot includes upper case char
   */
  public checkForUppercase(fieldValue) {
    // if (fieldValue && (fieldValue !== fieldValue.toLowerCase())) {
    if (fieldValue.match(/[a-z]/i)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * function for checking whether the given length is btwn 6 and 8
   * @param fieldValue = value to be tested
   * @returns true if length is btwn 6 and 8
   * @returns returns false if length is not btwn 6 and 8
   */
  public checkForPaswdLength(fieldValue) {
    if (fieldValue && fieldValue.length >= 8 && fieldValue.length <= 10) {
      return true;
    } else {
      return false;
    }
  }
}
