import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ApiService, ApiUrlConstants } from 'src/app/shared/utils/imports';

@Component({
  selector: 'app-document-view',
  templateUrl: './view-document.component.html'
})
export class DocumentViewComponent implements OnInit {
  @Input() viewDocInput;
  @Output() viewDocOutput = new EventEmitter<any>();

  public pdfPath;
  public documentDetails;

  constructor(
    // tslint:disable-next-line: deprecation
    @Inject(DOCUMENT) private document: Document,
    private readonly api: ApiService
  ) { }

  ngOnInit() {
    if (this.viewDocInput) {
      switch (this.viewDocInput.page) {
        case 'directory-view':
          const url = ApiUrlConstants.URL.directoryDocumentURL;
          const requestBody = {
            DocumentID: this.viewDocInput.DocumentID,
            DocumentName: this.viewDocInput.DocumentName,
            DirectoryTypeName: this.viewDocInput.DirectoryTypeName
          };
          this.pdfPath  = '';
          this.api.ExecutePost(url, requestBody, (response) => {
            if (response.ResponseCode === 200 && response.Data) {
              this.document.body.classList.add('overflow-hide');
              this.pdfPath = `data:application/pdf;base64,${response.Data}`;
            }
          }, (error) => {
          });
          break;

      }
    }
  }
  /*For close Instruction dialogbox */
  public closeInstructionDialog() {
    this.document.body.classList.remove('overflow-hide');
    this.viewDocOutput.emit();
  }
}
