import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly http: HttpClient,
    private readonly common: CommonService,
    private readonly router: Router) { }

  /*
	 * @desc set headers for the api calls.
	 */
  private getHeader() {
    return {
      'Content-Type': 'application/json'
    };
  }

  /*
	 * @desc set headers for the api calls with token.
	 */
  private getHeaderWithToken() {
    return {
      Authorization: 'Bearer ' + this.common.getUserToken(),
      'Content-Type': 'application/json'
    };
  }

  /*
	 * @desc set headers for the api calls without token.
	 */
  private getHeaderWithoutToken() {
    return {
      'Content-Type': 'application/json'
    };
  }

  /*
	 * @desc Execute the post method.
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecuteGet(apiUrl, successCallback, failureCallback) {
    this.http.get(apiUrl, { headers: this.getHeaderWithToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }


  public ExecuteGetForDownload(apiUrl, successCallback, failureCallback) {
    this.http.get(apiUrl,{ headers: this.getHeaderWithToken(),responseType:'blob' ,observe : 'response' }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

  public ExecutePostForDownload(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getHeaderWithToken(),responseType:'blob' ,observe : 'response' }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

  /*
	 * @desc Execute the post method.
	 * @param apiUrl url of the api to be called.
	 * @param requestBody requestbody to be set to the api.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecutePost(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getHeaderWithToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }
  /*
	 * @desc Execute the get method using refresh token
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecuteGetRefresh(apiUrl, successCallback, failureCallback) {
    this.http.get(apiUrl, { headers: this.getHeaderWithoutToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

  public ExecutePostAsync(apiUrl, requestBody): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestBody, { headers: this.getHeaderWithToken() }).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          if (error.error.Message === '-408') {
            this.sessionTimeout();
          } else {
            reject(error);
          }
        }
      );
    });
  }

  /*
	 * @desc Execute the post method.
	 * @param apiUrl url of the api to be called.
	 * @param requestBody requestbody to be set to the api.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecutePut(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.put(apiUrl, requestBody, { headers: this.getHeaderWithToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }
  /*
	 * @desc Execute the delete method for the apis.
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecuteDelete(apiUrl, successCallback, failureCallback) {
    this.http.delete(apiUrl, { headers: this.getHeaderWithToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }
  /* post api without token
	 * @desc Execute the post method.
	 * @param apiUrl url of the api to be called.
	 * @param requestBody requestbody to be set to the api.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecutePostNoToken(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getHeader() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

  /* get api without token
	 * @desc Execute the post method.
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecuteGetNoToken(apiUrl, successCallback, failureCallback) {
    this.http.get(apiUrl, { headers: this.getHeader() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

	 /* function for Execute download
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExecuteDownload(apiUrl, successCallback, failureCallback) {
    this.http.get(apiUrl, { headers: this.getDownloadHeader(), responseType: 'blob' }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }
  /* function for Excel export
	 * @param apiUrl url of the api to be called.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public ExcelExport(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getHeaderWithToken() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }

  /* function for upload form data
   * @param apiUrl url of the api to be called.
   * @param requestBody requestbody to be set to the api.
	 * @param successCallback succes callback for the api call function.
	 * @param failureCallback failure callback for the api call function.
	 */
  public UploadFormData(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getUploadHeader() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });
  }
  public ExecuteUpload(apiUrl, requestBody, successCallback, failureCallback) {
    this.http.post(apiUrl, requestBody, { headers: this.getUploadHeader() }).subscribe(response => {
      successCallback(response);
    }, error => {
      if (error.error.Message === '-408') {
        this.sessionTimeout();
      } else {
        failureCallback(error);
      }
    });


  }
  private getDownloadHeader() {
    return {
      Accept: 'application/pdf',
      'Access-Control-Allow-Origin': '*'
    };
  }

  private getUploadHeader() {
    return {
      Authorization: 'Bearer ' + this.common.getUserToken(),
    };
  }

  sessionTimeout() {
    console.log('API time out works');
    this.common.setTimeoutValue(true);
    sessionStorage.clear();
    // this.router.navigate(['login']);
    this.router.navigate(['../login']);
  }
}
