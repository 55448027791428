import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { ReportsUserAccessComponent } from '../tables/reports-user-access/reports-user-access.component';
import { ApiService, CommonService, ApiUrlConstants, MESSAGE_TEXT, LoaderService } from '../../utils/imports';

@Component({
  selector: 'app-excel-export-popup',
  templateUrl: './excel-export-popup.component.html'
})
export class ExcelexportpopupComponent implements OnInit {
  public downloadSrc = '';
  public downloadFileSrc = '';
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) { }
  public arrayBuffer;
  public showToaster = false;
  public showExportPopup = true;
  @ViewChild(ReportsUserAccessComponent) reportsTable: ReportsUserAccessComponent;

  @Output() excelpopupOutput = new EventEmitter();
  @Input() exportInput;
  public userAccessList = [];
  public itemsPerPage = 10;
  public itemsPer = {
    count: 0,
    totalItems: 0
  };
  public toasterData;
  ngOnInit() {
    this.loader.resetLoader();
  }

  /* Function for closing the toaster */
  public closeExcelpopup() {
    this.excelpopupOutput.next(false);
  }
  public exportToExcel() {
    this.callExportApi();
  }
  public callExportApi() {

    if (this.exportInput && this.exportInput.page) {
      let url;
      let requestBody;
      switch (this.exportInput.page) {
        case 'user-access':
          url = ApiUrlConstants.URL.userAccessReportExportExcelURL;
          this.exportInput.requestData.PageNumber = 0;
          this.exportInput.requestData.PageSize = 0;
          requestBody = this.exportInput.requestData;
          this.loader.startLoader();
          this.api.ExcelExport(url, requestBody, response => {
            if (response.Data) {
              this.loader.stopLoader();

              this.downloadSrc = response.Data.Base64;
              this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
              this.excelpopupOutput.next(false);
            }
          }, error => {
            this.loader.stopLoader();
            this.showExportPopup = false;
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
              message: error.error.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: 'EXPORT_FAILED',
              showToaster: true
            };
            this.showToaster = true;
          });
          break;

        case 'user-activity':
          url = ApiUrlConstants.URL.userActExportURL;
          this.exportInput.requestData.PageNumber = 0;
          this.exportInput.requestData.PageSize = 0;
          requestBody = this.exportInput.requestData;
          this.loader.startLoader();
          this.api.ExcelExport(url, requestBody, response => {
            if (response.Data) {
              this.loader.stopLoader();

              this.downloadSrc = response.Data.Base64;
              this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
              this.excelpopupOutput.next(false);
            }
          }, error => {
            this.loader.stopLoader();
            this.showExportPopup = false;
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
              message: error.error.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: 'EXPORT_FAILED',
              showToaster: true
            };
            this.showToaster = true;
          });
          break;

        case 'app-overview':
          url = ApiUrlConstants.URL.appOverviewExportURL;
          this.exportInput.requestData.PageNumber = 0;
          this.exportInput.requestData.PageSize = 0;
          requestBody = this.exportInput.requestData;
          this.loader.startLoader();
          this.api.ExcelExport(url, requestBody, response => {
            if (response.Data) {
              this.loader.stopLoader();

              this.downloadSrc = response.Data.Base64;
              this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
              this.excelpopupOutput.next(false);
            }
          }, error => {
            this.loader.stopLoader();
            this.showExportPopup = false;
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
              message: error.error.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: 'EXPORT_FAILED',
              showToaster: true
            };
            this.showToaster = true;
          });
          break;

        case 'respone-turn-around':
          url = ApiUrlConstants.URL.exportResponseTurnURL;
          this.exportInput.requestData.PageNumber = 0;
          this.exportInput.requestData.PageSize = 0;
          requestBody = this.exportInput.requestData;
          this.loader.startLoader();
          this.api.ExcelExport(url, requestBody, response => {
            if (response.Data) {
              this.loader.stopLoader();

              this.downloadSrc = response.Data.Base64;
              this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
              this.excelpopupOutput.next(false);
            }
          }, error => {
            this.loader.stopLoader();
            this.showExportPopup = false;
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
              message: error.error.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: 'EXPORT_FAILED',
              showToaster: true
            };
            this.showToaster = true;
          });
          break;

          case 'hedis':
            url = ApiUrlConstants.URL.hedisExportResponseTurnURL;
            this.exportInput.requestData.PageNumber = 0;
            this.exportInput.requestData.PageSize = 0;
            requestBody = this.exportInput.requestData;
            this.loader.startLoader();
            this.api.ExcelExport(url, requestBody, response => {
              if (response.Data) {
                this.loader.stopLoader();
                this.downloadSrc = response.Data.Base64;
                this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
                this.excelpopupOutput.next(false);
              }
            }, error => {
              this.loader.stopLoader();
              this.showExportPopup = false;
              this.toasterData = {
                heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
                message: error.error.ResponseMessage,
                buttonText: MESSAGE_TEXT.ALERT_BUTTON,
                type: 'EXPORT_FAILED',
                showToaster: true
              };
              this.showToaster = true;
            });
            break;

            case 'today-dashboard':
            url = ApiUrlConstants.URL.todayDashboardExcelUrl;
            requestBody = this.exportInput.requestData;
            this.loader.startLoader();
            this.api.ExcelExport(url, requestBody, response => {
              if (response.Data) {
                this.loader.stopLoader();
                this.downloadSrc = response.Data.Base64;
                this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
                this.excelpopupOutput.next(false);
              }
            }, error => {
              this.loader.stopLoader();
              this.showExportPopup = false;
              this.toasterData = {
                heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
                message: error.error.ResponseMessage,
                buttonText: MESSAGE_TEXT.ALERT_BUTTON,
                type: 'EXPORT_FAILED',
                showToaster: true
              };
              this.showToaster = true;
            });
            break;

            case 'user-emails':
            url = ApiUrlConstants.URL.userEmailListExportUrl;
            requestBody = this.exportInput.requestData;
            this.exportInput.requestData.PageNumber = 0;
            this.exportInput.requestData.PageSize = 0;
            this.loader.startLoader();
            this.api.ExcelExport(url, requestBody, response => {
              if (response.Data) {
                this.loader.stopLoader();
                this.downloadSrc = response.Data.Base64;
                this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
                this.excelpopupOutput.next(false);
              }
            }, error => {
              this.loader.stopLoader();
              this.showExportPopup = false;
              this.toasterData = {
                heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
                message: error.error.ResponseMessage,
                buttonText: MESSAGE_TEXT.ALERT_BUTTON,
                type: 'EXPORT_FAILED',
                showToaster: true
              };
              this.showToaster = true;
            });
            break;
          case 'pcp_update_requests':
          url = ApiUrlConstants.URL.pcpUpdateRequestReportExportUrl;
          this.exportInput.requestData.PageNumber = 0;
          this.exportInput.requestData.PageSize = 0;
          requestBody = this.exportInput.requestData;
          this.loader.startLoader();
          this.api.ExcelExport(url, requestBody, response => {
            if (response.Data) {
              this.loader.stopLoader();
              this.downloadSrc = response.Data.Base64;
              this._base64ToArrayBuffer(this.downloadSrc, response.Data.FileName);
              this.excelpopupOutput.next(false);
            }
          }, error => {
            this.loader.stopLoader();
            this.showExportPopup = false;
            this.toasterData = {
              heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
              message: error.error.ResponseMessage,
              buttonText: MESSAGE_TEXT.ALERT_BUTTON,
              type: 'EXPORT_FAILED',
              showToaster: true
            };
            this.showToaster = true;
          });
          break;
      }
    }
  }
  /** Function for close the toaster
   * @param event output event
   */
  public closeToaster(event) {
    this.showToaster = false;
    this.excelpopupOutput.next(false);
  }
  public _base64ToArrayBuffer(base64, filename) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    this.createAndDownloadBlobFile(bytes.buffer, filename);
  }

  public createAndDownloadBlobFile(body, filename, extension = 'xlsx') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

}
