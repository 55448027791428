import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html'
})
export class DateFieldComponent implements OnInit {
  @Input() dateFieldInput;
  @Output() dateFieldOutput = new EventEmitter();
  public showDatepicker = false;
  public datePickerData;
  constructor() { }

  ngOnInit() {
    this.datePickerData = this.dateFieldInput;
  }
  /* fn for toggling the date picker */
  public toggleDatePicker() {
    this.showDatepicker = !this.showDatepicker;
  }
  /* output event from date picker component */
  public eventFromDatePicker(event) {
    this.showDatepicker = false;
    this.dateFieldOutput.next(event);
  }
}
