import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import {
  CommonService,
  MESSAGE_TEXT,
  ApiService,
  ApiUrlConstants,
  PatternConstants,
  LoaderService,
} from "../../utils/imports";
import { DateValidator } from "../../validators/date-validator";
import { Observable } from "rxjs";
@Component({
  selector: "app-terms-and-conditions-popup",
  templateUrl: "./terms-and-conditions-popup.component.html",
})
export class TermsAndConditionsPopupComponent implements OnInit {
  @Input() TAndCRequestInput;
  @Output() TAndCRequestOutput = new EventEmitter();
  public termsandcondtnform: FormGroup;
  public phoneNumberMask = PatternConstants.phoneNumberPattern;
  public loading = false;
  public medicalRecordTypeList = [];
  public companyLists = [];
  public isCompanyDisplay = 0;
  public isChenMed = 0;
  public sendingMethod = "Email";
  public formSubmit = false;

  public toasterData;
  public showToaster = false;
  public tandcAcceptDiv=false;

  constructor(
    private readonly api: ApiService,
    private readonly datepipe: DatePipe,
    private readonly common: CommonService,
    private readonly formBuilder: FormBuilder,
    public readonly loader: LoaderService
  ) {}

  ngOnInit() {
    this.createForms();
    var userIdentificationNum= this.TAndCRequestInput.userID;
    console.log(userIdentificationNum);
    if(userIdentificationNum > 0)
    {
      this.tandcAcceptDiv=true;
    }
    else
    {
      this.tandcAcceptDiv=false;
    }
  }

  /* Function for creating the form elements */
  private createForms() {
    this.termsandcondtnform = this.formBuilder.group({
      acceptTC: [true, Validators.required],
    });
  }

  /* Function for close confirm dialog */
  public closeConfirm(action) {
    console.log("In " + action);
    if (action == "success") {
      this.TAndCRequestOutput.next(action);
    } else {
      this.TAndCRequestOutput.next(false);
    }
  }

  /* Function for request click */
  public tAndCAgreeClick() {
    // debugger;
    this.formSubmit = true;
    // this.loading = true;
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.termsAndConditionsAcceptURL;
    console.log("userID " +this.TAndCRequestInput.userID);
    const requestBody = {
      UserID: this.TAndCRequestInput.userID,
    };
    this.api.ExecutePost(
      url,
      requestBody,
      (response) => {
        this.loader.stopLoader();
        console.log("ResponseCode " + response.ResponseCode);
        if (response.ResponseCode === 200) {
          this.toasterData = {
            heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
            message: MESSAGE_TEXT.TERMS_AND_CONDTN_APPROVED_SUCCESSFULLY,
            buttonText: MESSAGE_TEXT.ALERT_BUTTON,
            type: "REQUEST_SUCCESS",
            showToaster: true,
          };
          this.showToaster = true;
        }
      },
      (error) => {
        this.loader.stopLoader();
        this.toasterData = {
          status: "FAILURE",
          heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          message: MESSAGE_TEXT.SERVER_ERROR_MSG,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: "REQUEST_FAILED",
          showToaster: true,
        };
        this.showToaster = true;
      }
    );
  }

  /* function for check all fields valid */
  public disableAgreeButton() {
    if (this.termsandcondtnform.value.acceptTC) {
      return false;
    } else {
      return true;
    }
  }

  /* Function for close the toaster */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    if (event.type === "REQUEST_SUCCESS") {
      this.closeConfirm("success");
    }
  }
}
