export const MESSAGE_TEXT = {
    // tslint:disable-next-line:max-line-length
    REG_SUCCESS: 'Thank you for registering for Aran Eye Associates Portal. Please check back in 24-72 hours for activation of your account.If you do not get an activation email, try registering again or write to us at support@araneye.com.',
    APPOINTMENT_SUCCESS: 'Thank you for your appointment request. Please check back in 24-42 hours for the appointment time.',
    RESCHEDULE_SUCCESS: 'Thank you for your reschedule request. Please check back in 24-42 hours for the new appointment time.',
    SERVER_ERROR_MSG: 'Something went wrong. Please try again soon.',
    SERVER_ERROR_TITLE: 'Server Error',
    NOT_FOUND_ERROR: 'No data found',
    ALERT_BUTTON: 'OK',
    ALERT_FAIL_TITLE: 'FAILURE',
    ALERT_SUCCESS_TITLE: 'SUCCESS',
    CONFIRM_HEADING: 'CONFIRMATION',
    PCP_UPDATE_ALERT: 'The system has matched more than one patient with the information entered. The PCP name in our system in the patients chart does not match your PCP clinics name for all patients found. You can press the cancel button and see the patients matched to your clinic to see if that is the patient you are searching for or you can select “Yes, Proceed” and a request to update the PCP in the patients chart will be created and our support team will make the update.',
    PATIENT_EXIST_ALERT:'Patient is in the system but assigned to a different PCP. Please select OK and complete the primary care field by selecting the primary care name and access will be provided to you',
    PATIENT_EXIST_ALERT_ADDRESS:'Patient is in the system but assigned to a different PCP. Please select OK and complete the primary care field by selecting the primary care name and access will be provided to you',
    PATIENT_NOT_EXIST_ALERT:'Patient does not exist. You can create a new patient by proceeding, or click on cancel button to search again.',
    // tslint:disable-next-line: max-line-length
    CONFIRM_USER_ACTIVE: 'Are you sure want to Deactivate the User?',

    // tslint:disable-next-line:max-line-length
    CONFIRM_ACTIVE: 'By making the clinic status inactive you will be restricting the access to the portal for all the users within this clinic. Do you wish to proceed?',
    // tslint:disable-next-line:max-line-length
    CONFIRM_IN_ACTIVE: 'By making the clinic status active, you are allowing all the users within this clinic to access the portal. Do you wish to proceed?',
    CANCEL_BUTTON: 'Cancel',
    CONFIRM_BUTTON: 'Yes',
    NO_BUTTON: 'No',
    CONFIRM_DELETE_MSG: 'Are you sure you want to delete this user?',
    CONFIRM_SEND_NOTI: 'Are you sure you want to send this notification?',
    CONFIRM_SEND_BUTTON: 'Yes, Send notification',
    CONFIRM_DLT_CLINIC: 'Yes, Delete the Center',
    CONFIRM_DLT_USER: 'Yes, Delete the user',
    CONFIRM_PROCEED: 'Yes, Proceed',
    CONFIRM_UNLOCK_PROCEED:'Yes, Unlock the user',
    CONFIRM_LOCK_PROCEED:'Yes, Lock the user',
    DLT_USER_MSG: 'Are you sure you want to Delete the user',
    DLT_CLINIC_MSG: 'Are you sure you want to Delete the Center',
    FILE_UPLOAD_ERROR_MSG: 'File upload failed, Please try again.',
    USER_UNLOCK_MSG:'Are you sure want to unlock the user',
    USER_LOCK_MSG:'Are you sure want to lock the user',
    SURGERY_TIME_TBA_HOVER_MSG:'The surgery center will call the patient 1 day before with their arrival time for surgery',
    CONFIRM_PCP_REQUEST:'Yes, Request needs to be send',
    TERMS_AND_CONDTN_APPROVED_SUCCESSFULLY:'Terms and conditions have been approved. You can continue to login by clicking on OK button.'
};

