import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as CryptoJS from 'crypto-js';

import { NgxPaginationModule} from 'ngx-pagination';
import { ClickOutsideModule } from 'ng4-click-outside';
import { SharedModule } from '../app/shared/shared.module';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgIdleModule } from '@ng-idle/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DataService } from './home/common-pages/components/patient-dashboard/data.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    SharedModule,
    ClickOutsideModule,
    MyDateRangePickerModule,
    AutocompleteLibModule,
    PdfViewerModule,
    NgSelectModule,
    FormsModule,
    NgIdleModule.forRoot()
  ],
  providers: [DataService],
  bootstrap: [AppComponent],
  exports: [
    NgxPaginationModule,
    ClickOutsideModule,
    MyDateRangePickerModule,
    AutocompleteLibModule,
    PdfViewerModule
  ]
})
export class AppModule { }
