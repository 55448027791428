import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';

@Component({
  selector: 'app-clinic-filter',
  templateUrl: './clinic-filter.component.html'
})
export class ClinicFilterComponent implements OnInit {
  @Input() dropdownFilterClinic;
  @Output() clinicFilterOutput = new EventEmitter();
  public showList;
  public clinicList = [];
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getClinicList();
  }

  ngOnInit() {
    this.showList = this.dropdownFilterClinic.show;
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterClinic.Name = 'ALL';
    this.dropdownFilterClinic.Id = null;
    this.clinicFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterClinic.Name = item.Name;
    this.dropdownFilterClinic.Id = item.Id;
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterClinic.show = false;
  }
  /* get the list of locations */
  private getClinicList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.clinicsListURL;
    const requestBody = {
      PageCount: null,
      PageNumber: null,
      Name: ''
    };
    this.clinicList = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.length) {
        this.clinicList = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }
}
