import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, LoaderService, ApiUrlConstants, MESSAGE_TEXT, ArrayConstants, CommonService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-reports-user-access',
  templateUrl: './reports-user-access.component.html'
})
export class ReportsUserAccessComponent implements OnInit {
  @Input() tableFilter;
  public loading;
  public toasterData;
  public showToaster = false;
  public showConfirm = false;
  public confirmData;
  public item;
  public showViewReport = false;
  public userType;
  public itemsPerPage = 10;
  public itemsPer = {
    count: 0,
    totalItems: 0
  };
  public itemsPerList = ArrayConstants.itemsPerPageArray;
  public userAccessList = [];
  public activeUser;
  public sortIcons = {
    sortName: '',
    sortRole: '',
    sortAccess: '',
    sortLogin: '',
    sortDownload: '',
    sortClinic: '',
    sortLogout:''
  };
  public currentPage = 1;
  public viewReportData = {
    page: 'user-access',
    data: null
  };
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    this.checkUserType();
  }
  /* Function for check the type of user logged */
  public checkUserType() {
    this.userType = this.common.getUserType();
    this.getUserAccessList();
  }
  private resetSortIcons() {
    this.sortIcons = {
      sortName: '',
      sortRole: '',
      sortAccess: '',
      sortLogin: '',
      sortDownload: '',
      sortClinic: '',
      sortLogout:'',
    };
  }
  /* Function for sorting the table column */
  public sortColumn(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'Name':
        this.resetSortIcons();
        this.sortIcons.sortName = sortOrder;
        break;
      case 'Role':
        this.resetSortIcons();
        this.sortIcons.sortRole = sortOrder;
        break;
      case 'AccessProvided':
        this.resetSortIcons();
        this.sortIcons.sortAccess = sortOrder;
        break;
      case 'LastLogin':
        this.resetSortIcons();
        this.sortIcons.sortLogin = sortOrder;
        break;
        case 'LastLogout':
          this.resetSortIcons();
          this.sortIcons.sortLogout = sortOrder;
          break;
      case 'Clinic':
        this.resetSortIcons();
        this.sortIcons.sortClinic = sortOrder;
        break;
    }
    this.userAccessList = this.common.sortColumn(this.userAccessList, columnToSort, sortOrder);
  }
  /* Function for sort number column */
  public sortNumber(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'DownloadedDocument':
        this.resetSortIcons();
        this.sortIcons.sortDownload = sortOrder;
        break;
    }
    this.userAccessList = this.common.sortNumber(this.userAccessList, columnToSort, sortOrder);
  }
  /* Function for trigger page change event */
  public pageChange(newPage: number) {
    this.currentPage = newPage;
    this.tableFilter.requestData.PageNumber = newPage;
    this.getUserAccessList();
  }

  /* Function for trigger when items per change value is changed */
  public itemsPerPageChange() {
    this.tableFilter.requestData.PageSize = this.itemsPerPage;
    this.tableFilter.requestData.PageNumber = 1;
    this.getUserAccessList();
  }
  public viewReport(item) {
    this.showViewReport = true;
    this.viewReportData.data = item;
  }
  public viewReportEvent(event) {
    this.showViewReport = false;
  }
  public getUserAccessList() {
    this.loader.startLoader();
    if (this.tableFilter && this.tableFilter.requestData) {
      if (this.tableFilter.requestData.PageSize) {
        this.itemsPerPage = this.tableFilter.requestData.PageSize;
      } else {
        this.itemsPerPage = 10;
      }
      this.currentPage = this.tableFilter.requestData.PageNumber;
    }

    const url = ApiUrlConstants.URL.userAccessReportURL;
    const requestBody = this.tableFilter.requestData;
    this.userAccessList = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.UserAccessLists && response.Data.UserAccessLists.length) {
        this.userAccessList = response.Data.UserAccessLists;
        this.itemsPer.totalItems = response.Data.TotalRecords;
        this.itemsPer.totalItems <= this.userAccessList.length ?
          this.itemsPer.count = this.itemsPer.totalItems :
          this.itemsPer.count = this.userAccessList.length;
        this.resetSortIcons();
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.itemsPer.totalItems = 0;
      this.userAccessList = [];
    });
  }
  public activeInactiveChange(item) {

    this.item = item;
    if (!item.IsDeactivated) {
      this.confirmData = {
        heading: MESSAGE_TEXT.CONFIRM_HEADING,
        message: MESSAGE_TEXT.CONFIRM_USER_ACTIVE,
        delete: 'USERDEACTIVE',
        rightButton: MESSAGE_TEXT.CONFIRM_PROCEED,
        type: 'CONFIRM_ACT_INACT',
        confirmAction: ''
      };
      this.showConfirm = true;
    } else {
      this.toasterData = {
            heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
            message: 'User is inactive',
            buttonText: MESSAGE_TEXT.ALERT_BUTTON,
            type: 'API_FAILED',
            showToaster: true
          };
      this.showToaster = true;
    }
  }
  public closeToaster(event) {
    this.showToaster = false;
  }
  public closeConfirm(event) {
    this.showConfirm = false;
    if (event.confirmAction === 'confirm') {
      const url = ApiUrlConstants.URL.deactivateUrl;
      const requestBody = {
        RemovedUserID: this.item.UserKey,
        UserKey: this.common.getUserID(),
      };
      this.api.ExecutePost(url, requestBody, (response) => {
        this.loading = false;
        if (response.ResponseCode === 200) {
          this.item.IsDeactivated = !this.item.IsDeactivated;
          this.getUserAccessList();
        }
      }, (error) => {
        this.loading = false;
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
          message: error.error.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'API_FAILED',
          showToaster: true
        };
        this.showToaster = true;
      });
    }
  }
}
