import { Component, OnInit, Input } from '@angular/core';
import { ApiService, ArrayConstants, LoaderService, CommonService, ApiUrlConstants } from 'src/app/shared/utils/imports';

@Component({
  selector: 'app-hedis-table',
  templateUrl: './hedis-table.component.html'
})
export class HedisTableComponent implements OnInit {
  @Input() tableFilter;
  public hedisList = [];
  public sortIcons = {
    sortFirstName: '',
    sortLastName: '',
    sortDOB: '',
    sortAppDate: ''
  };
  public itemsPerList = ArrayConstants.itemsPerPageArray;
  public itemsPerPage = 10;
  public itemsPer = {
    count: 0,
    totalItems: 0
  };
  public currentPage = 1;
  public userType;
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    this.checkUserType();
  }
  /* Function for check the type of user logged */
  public checkUserType() {
    this.userType = this.common.getUserType();
    this.getHedisList();
  }
  /* Function for trigger for page change event */
  public pageChange(newPage: number) {
    this.currentPage = newPage;
    this.tableFilter.requestData.PageNumber = newPage;
    this.getHedisList();
  }

  /* Function for trigger when items per change value is changed */
  public itemsPerPageChange() {
    this.tableFilter.requestData.PageSize = this.itemsPerPage;
    this.tableFilter.requestData.PageNumber = 1;
    this.getHedisList();
  }
  /* Function for sorting the table column */
  public sortColumn(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'FirstName':
        this.resetSortIcons();
        this.sortIcons.sortFirstName = sortOrder;
        break;
      case 'LastName':
        this.resetSortIcons();
        this.sortIcons.sortLastName = sortOrder;
        break;
      case 'DOB':
        this.resetSortIcons();
        this.sortIcons.sortDOB = sortOrder;
        break;
      case 'AppDate':
        this.resetSortIcons();
        this.sortIcons.sortAppDate = sortOrder;
        break;
    }
    this.hedisList = this.common.sortColumn(this.hedisList, columnToSort, sortOrder);
  }
  /* Function for hiding sort icon*/
  private resetSortIcons() {
    this.sortIcons = {
      sortFirstName: '',
      sortLastName: '',
      sortDOB: '',
      sortAppDate: ''
    };
  }
  getHedisList() {
    this.itemsPer.totalItems = 3;
    this.hedisList = [
      {
        FirstName : 'Jarvis',
        LastName: 'Masus',
        DOB : '25 Nov 74',
        AppDate : '25 Nov 75',
        CPTCode : '2022F',
        DiagnosisCode : '89658'
      },
      {
        FirstName : 'Jarvis',
        LastName: 'Masus',
        DOB : '25 Nov 74',
        AppDate : '25 Nov 75',
        CPTCode : '2022F',
        DiagnosisCode : '89658'
      },
      {
        FirstName : 'Jarvis',
        LastName: 'Masus',
        DOB : '25 Nov 74',
        AppDate : '25 Nov 75',
        CPTCode : '2022F',
        DiagnosisCode : '89658'
      }
    ];
  }
}
