
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-auto-complete-state',
  templateUrl: './auto-complete-state.component.html'
})
export class AutoCompleteStateComponent implements OnInit {

  @Output() stateOutput = new EventEmitter();
  @Input() stateInput;
  public searchParam;
  public stateArray = [];
  public keyword = 'Name';
  public displayField = true;
  public stateList = [];
  public stateComplete = [];
  public selecteventData =
  {
    ID: 9,
    Name: 'Florida'
  };
  public initValue;
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    // this.initValue = 'Florida';

    if (this.stateInput && this.stateInput.Name && this.stateInput.ID) {
      this.searchParam = this.stateInput.Name;
      this.selecteventData.Name = this.stateInput.Name;
      this.selecteventData.ID = this.stateInput.ID;
      this.initValue = this.stateInput.Name;
    } else {
      this.stateInput.ID = 9;
      this.stateInput.Name = 'Florida';
      this.selecteventData.ID = 9;
      this.selecteventData.Name = 'Florida';
      this.initValue = 'Florida';
      this.searchParam = 'Florida';
    }
    this.getStateList();
    this.selectEvent(this.selecteventData);
  }

  /* Function for get the list of locations */
  private getStateList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.statesListURL;
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.stateList = response.Data;
        this.stateComplete = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.stateList = [];
    });
  }

  /* Function for trigger when input is changed */
  public stateListInput() {
    this.stateList = [];
    this.stateComplete.forEach(element => {
      if (element.Name.toLowerCase().startsWith(this.searchParam.toLowerCase())) {
        this.stateList.push(element);
      }
    });
  }

  /** Function for trigger when an item is selected from the list
   * @param event output event
   */
  public selectEvent(event) {
    // tslint:disable-next-line: triple-equals
    if (event == 'Florida') {
      this.stateInput.Name = 'Florida';
      this.stateInput.ID = 9;
      this.stateOutput.next(this.stateInput);
    } else if (event.Name == undefined) {
      this.stateInput.Name = event;
      this.stateOutput.next(this.stateInput);
      
    } else {
     this.stateInput.Name = event.Name;
     this.stateInput.ID = event.ID;
     this.stateOutput.next(this.stateInput);
   }
  }

  /* Function for trigger when the panel is closed */
  public closePanel() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });

    this.stateList = this.stateComplete;
  }

  /* Function for clear the input field */
  public clearState() {
    this.searchParam = '';
    this.stateOutput.next(null);
    this.stateList = this.stateComplete;
  }
  public opened() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
}
