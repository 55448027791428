import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html'
})
export class ConfirmationPopupComponent implements OnInit {
  @Output() confirmOutput = new EventEmitter();
  @Input() confirmInput;
  constructor() { }

  ngOnInit() {
  }

  /** Function for closing the toaster
   * @param action type of the action
   */
  public closeConfirm(action) {
    this.confirmInput.confirmAction = action;
    this.confirmOutput.next(this.confirmInput);
  }
}
